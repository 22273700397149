import { Box, Flex, Heading } from "@radix-ui/themes";
import styles from "./PunchyKicky.module.scss";
import CustomCarousel from "../../shared/components/carousel/carousel";
import carouselData from "../../shared/constants/homeCarousel.json";

const PunchyKicky = () => {
  return (
    <>
      <Flex className={styles.mainContainer} direction={"column"}>
        <Box className="ComingSoonRadialCover"/>
        <Flex align="center" justify="center"  className="ComingSoonText">
          <Heading  align="center"size="9">COMING SOON</Heading>
        </Flex>
        
        <Box className="ComingSoonCarouselContainer">
          <CustomCarousel cards={carouselData} />
        </Box>
        </Flex>
    </>
  );
};

export default PunchyKicky;
