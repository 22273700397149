import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Text
} from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { useCartStore } from "../../../state";
import { productsData } from "../../constants/globalConstants";
import SubmitEmailField from "../submit-email-field/submit-email-field";

const anyProductsData = productsData as any;

interface ExplainerSectionProps {
  title: string;
  description: string;
  listItems: string[];
  image: string;
  productName: string;
}

const ExplainerSection: React.FC<ExplainerSectionProps> = ({
  title,
  description,
  listItems,
  image,
  productName,
}) => {
  const navigate = useNavigate();
  const productsData = useCartStore((state) => state.productsData);

  const amazonLink = productsData[productName]?.amazonLink;
  const comingSoon = productsData[productName]?.comingSoon;

  const handleBuyNowClick = (productName: string) => {
    if (amazonLink && comingSoon) {
      window.open(amazonLink, '_blank')
    } else {
      navigate(`/shop/${productName.toLowerCase()}`)
    }
  }

  return (
    <Box className={"ExplainerSection"} py="9">
      <Container>
        <Grid columns={{ initial: "1", md: "2" }}>
          <Flex mb="9"align={"center"} justify="center">
            
              <img src={image} alt="Game Box"  width={"80%"}/>
            
          </Flex>

          <Flex justify="center"direction={"column"}>
            <Heading as="h2" size={"9"}>
              {title}
            </Heading>
            <Text mt="3">{description} </Text>

            <Flex mt="6">
              <ul className="disc">
                {listItems.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </Flex>

            {comingSoon && !amazonLink? <>
                <Text mt="9" mb="3">
                {anyProductsData[productName]["title"]} hasn't been released yet! Enter your email below and we will notify you when it's released.

              </Text>
                <SubmitEmailField />              
              </> : <>
              
              <Button className="BuyNowButton" mt="9" size="4" onClick={() => handleBuyNowClick(productName) }>
                Buy Now
              </Button>
              </> }
          </Flex>
        </Grid>
      </Container>
    </Box>
  );
};

export default ExplainerSection;
