import { MinusIcon, PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { Box, Card, Flex, Grid, Heading, IconButton, Text } from "@radix-ui/themes";
import { ProductsData, useCartStore } from "../../../state";
import { productsData } from "../../constants/globalConstants";
import styles from "./cart.module.scss";
import { useNavigate } from "react-router-dom";


interface CartItemProps {
  product: any;
}

const CartItem: React.FC<CartItemProps> = ({ product }) => {
  const addOneToCart = useCartStore((state) => state.addOneToCart);
  const removeOneFromCart = useCartStore((state) => state.removeOneFromCart);
  const productsData = useCartStore((state) => state.productsData);

  return (
    <>
      <Box my="4">
        <hr />  
      </Box>
      <Box className={styles.itemCard}>
     <Flex direction={{initial:"column", sm:"row"}} align="center">
          <Flex className={styles.imageColumn}>
            <img
              className={styles.cartImage}              
              src={productsData[product.path]?.image}
              alt={`Image of ${productsData[product.path]?.title}`}
            />
          </Flex>
          <Flex p="5" className={styles.detailsColumn}  direction={"column"}>
            <Heading as="h1" size="7">
              {productsData[product.path]?.title}
            </Heading>
            <Flex direction="column">
              <Text size="5" mt="1">
                £
                {(
                  productsData[product.path]?.price * product?.quantity
                ).toFixed(2)}
              </Text>
            </Flex>
          </Flex>
          <Flex className={styles.quantityColumn} align={"center"} justify="center" >
                <IconButton onClick={() => removeOneFromCart(product?.id)}>
                  {product?.quantity === 1 ? <TrashIcon /> : <MinusIcon />}
                </IconButton>
                <Text mx="3">{product?.quantity}</Text>
                <IconButton onClick={() => addOneToCart(product?.id, product?.path)}>
                  <PlusIcon />
                </IconButton>
              </Flex>
              </Flex>
      </Box>
    </>
  );
};

export default CartItem;
