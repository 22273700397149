import { Box, Flex, Grid, Heading } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { routesConfig } from "../../../App";
import SubmitEmailField from "../submit-email-field/submit-email-field";
import styles from "./Footer.module.scss";
import logo from "/shared/images/logo.webp";

const Footer = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Grid columns={{ initial: "1", md: "4" }} py="9" px="6" className={styles.Footer}>
      <Flex direction={{ initial: "column" }} align={"center"}>
        <Link to={routesConfig["HOME"].path} onClick={handleClick}>
          <img src={logo} alt="poop" width="200px" />
        </Link>
      </Flex>
      <Flex direction={"column"}>
        <Heading as="h3" mb="3">
          Our Games
        </Heading>
        <Link to={routesConfig.JINX.path} className={styles.FooterLink}>
          Jinx
        </Link>
        <Link to={routesConfig.SNEAKY_GOOSE.path} className={styles.FooterLink}>
          Sneaky Goose
        </Link>
      </Flex>
      <Flex direction={"column"}>
        <Heading as="h3" mb="3">
          Support
        </Heading>
        <Link to={routesConfig.CONTACT.path} className={styles.FooterLink}>
          Help & Contact
        </Link>
        <Link to={routesConfig.PRIVACY.path} className={styles.FooterLink}>
          Privacy
        </Link>
        <Link to={routesConfig.TERMS.path} className={styles.FooterLink}>
          Terms of Service
        </Link>
      </Flex>
      <Flex direction={"column"}>
        <Heading as="h3" mb="3">
          Never miss a game release!
        </Heading>
        <Box>
          <SubmitEmailField />
        </Box>
      </Flex>
    </Grid>
  );
};

export default Footer;
