import React from "react";
import logo from "/shared/images/logo.webp";
import carbon from "/shared/images/carbonNeutralStampBlack.webp";
import uk from "/shared/images/unionJack.webp";
import { Box, Card, Flex, Grid, Heading, Text } from "@radix-ui/themes";

const WhyChooseUs: React.FC = () => {
  return (
    <Grid
      justify="between"
      gap="5"
      px={{ initial: "4", md: "6" }}
      columns={{ initial: "1", md: "3" }}
    >
      <Card className="BlackCard testimonial-card">
        <Flex
          direction="column"
          justify="center"
          align="center"
          p="7"
          gap="5"
          className="wholesaleCard"
        >
          <Box height={"120px"}>
            <img width="200px" src={carbon} />
          </Box>
          <Heading as="h3" size="6" align={"center"}>
            Carbon Neutral
          </Heading>
          <Text align={"center"}>
            We are proud to be carbon neutral. Play with peace of mind knowing
            that your fun doesn’t come at the planet’s expense.
          </Text>
        </Flex>
      </Card>
      <Card className="BlackCard testimonial-card">
        <Flex
          direction="column"
          justify="center"
          align="center"
          p="7"
          gap="5"
          className="wholesaleCard"
        >
          <Box height={"120px"}>
            <img width="150px" src={uk} />
          </Box>
          <Heading as="h3" size="6" align={"center"}>
            Made in the UK
          </Heading>
          <Text align={"center"}>
            All our games are designed, created, and printed in the UK. This
            means every game in our range has the Worgan Games standard of
            quality control. We are proud to say that every game we sell is made
            to the very highest standard.
          </Text>
        </Flex>
      </Card>{" "}
      <Card className="BlackCard testimonial-card">
        <Flex
          direction="column"
          justify="center"
          align="center"
          p="7"
          gap="5"
          className="wholesaleCard"
        >
          <Box height={"120px"}>
            <img width="150px" src={logo} />
          </Box>
          <Heading as="h3" size="6" align={"center"}>
            Fun
          </Heading>
          <Text align={"center"}>
            At Worgan Games, our brand stands for fun and enjoyment. When you
            see our logo, you know a great time awaits. We’re passionate about
            creating engaging games, and our enthusiasm shines through in every
            product.
          </Text>
        </Flex>
      </Card>
    </Grid>
  );
};

export default WhyChooseUs;
