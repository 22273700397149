import { Container, Heading } from "@radix-ui/themes";

const Privacy = () => {
  return (
      <Container py="9">
        <Heading as="h1">Privacy Policy</Heading>
        <h2>Overview – the key information you should be aware of</h2>
        <p><strong>(A) Who we are:</strong> We are BALL TECHNOLOGY LTD T/A WORGAN GAMES. We make a variety of fun card games that are all carbon neutral. If you need it, our company number is 13911157, incorporated on 11 February 2022, and our registered office is Pure Offices, Unit 16 Lakeview 600, Lakeside Drive, Centre Park Square, Warrington, England, WA1 1RW. BALL TECHNOLOGY LTD T/A WORGAN GAMES is the controller of your personal information and is responsible for your personal information. All references in this Notice to “Company”, “our”, “us” or “we” refer to BALL TECHNOLOGY LTD T/A WORGAN GAMES. All references in this policy to “our website” refer to the website owned by us at <a href="https://www.worgangames.com">https://www.worgangames.com</a>.</p>
        
        <p><strong>(B) Our values and what this policy is for:</strong> We value your privacy and want to be accountable and fair to you as well as transparent with you in the way that we collect and use your personal information. We also want you to know your rights in relation to your information which you can find in part 6. In line with these values, this privacy notice tells you what to expect when we collect and use personal information about you. We have tried to make it easy for you to navigate so you can find the information that is most relevant to you and our relationship with you. We are always looking to improve the information we provide to our customers and contacts, so if you have any feedback on this privacy notice, please let us know using our contact details in section 13.</p>
        
        <p><strong>(C) Who this notice applies to:</strong> This notice applies to:</p>
        <ul>
            <li>Visitors to our website;</li>
            <li>Customers, and employees of customers;</li>
            <li>Prospective customers (to whom we send marketing communications), and employees of prospective customers;</li>
            <li>People who contact us with enquiries; and</li>
            <li>Our suppliers, and employees of our suppliers.</li>
        </ul>
        
        <p>Depending on our relationship, we will collect and use your information in different ways. Please click on the links above to find out the information that we collect about you and how we use this information.</p>
        
        <p><strong>(D) What this notice contains:</strong> This privacy notice describes the following important topics relating to your information (you can click on the links to find out more):</p>
        <ul>
            <li>How we obtain your personal information;</li>
            <li>Collection of your personal information and how we use it;</li>
            <li>Our legal basis for using your personal information;</li>
            <li>How and why we share your personal information with others;</li>
            <li>How long we store your personal information;</li>
            <li>Your rights;</li>
            <li>Children;</li>
            <li>Marketing;</li>
            <li>Where we may transfer your personal information;</li>
            <li>Risks and how we keep your personal information secure;</li>
            <li>Links to other websites;</li>
            <li>Changes to this privacy notice; and</li>
            <li>Further questions and how to make a complaint.</li>
        </ul>
        
        <p><strong>(E) Your rights to object:</strong> You have various rights in respect of our use of your personal information as set out in section 6. Two of the fundamental rights to be aware of are that:</p>
        <ul>
            <li>You may ask us to stop using your personal information for direct-marketing purposes. If you exercise this right, we will stop using your personal information for this purpose.</li>
            <li>You may ask us to consider any valid objections which you have to our use of your personal information where we process your personal information on the basis of our, or another person’s, legitimate interest.</li>
        </ul>
        
        <p>You can find out more information in section 6.</p>
        
        <p><strong>(F) What you need to do and your confirmation to us:</strong> Please read this privacy notice carefully to understand how we handle your personal information. By engaging with us in the ways set out in this privacy notice, you confirm that you have read and understood the entirety of this privacy notice, as it applies to you.</p>
        
        <h2>The detail – the key information you should be aware of</h2>
        <h3>1. How we obtain your personal information</h3>
        <p>1.1. You may provide us with your personal information yourself. You may give us personal information about yourself by using the online forms provided on our website, setting up an account with us or by contacting us by phone, email or other means. This includes, for example, where you provide your personal information to us in order to receive products, deliveries, information or services from us. If you are a supplier, you may also give us personal information about you when you are offering or providing services to us. If you are an employee, we may receive your personal information from your employer, for example, your email address, phone number or other contact information to allow us to be in touch with your employer.</p>
        <p>1.2. We may also receive information about you from third parties such as marketing agencies, market research companies, our suppliers, contractors and consultants, our group companies, public websites and public agencies, which we refer to as “third party sources” or “suppliers” throughout this notice.</p>
        
        <h3>2. Collection of your personal information and how we use it</h3>
        <p>Please go to the section or sections below that best describes our relationship with you to find out the information that we collect about you and how we use this information. We refer to this as “personal information” throughout this notice.</p>
        
        <h4>2.1. Visitors to our website</h4>
        <p>(a) <strong>What personal information we collect about you</strong><br />
        We, or third parties on our behalf, may collect and use any of the following information about you:</p>
        <ul>
            <li>Your name including your title;</li>
            <li>Your postal address;</li>
            <li>Your email address;</li>
            <li>Your telephone number;</li>
            <li>Information provided when you correspond with us;</li>
            <li>Any updates to information provided to us;</li>
            <li>Personal information we collect about you or that we obtain from our third-party sources;</li>
            <li>The following information created and recorded automatically when you visit our website:
                <ul>
                    <li>(A) <strong>Technical information.</strong> This includes: the Internet Protocol (IP) address used to connect your computer to the internet address; the website address and country from which you access information; the files requested; browser type and version; browser plug-in types and versions; operating system; and platform. We use this personal information to administer our website, to measure the efficiency of our systems and to undertake an analysis on the locations from which people access our webpages; and</li>
                    <li>(B) <strong>Information about your visit and your behaviour on our website.</strong> This may include the website you visit before and after visiting our website (including date and time), time and length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, traffic data, location data, weblogs and other communication data and information provided when requesting further service or downloads.</li>
                </ul>
            </li>
        </ul>
        
        <p>(b) <strong>How we use your personal information</strong><br />
        We will collect, use and store the personal information listed above for the following reasons:</p>
        <ul>
            <li>To allow you to access, use and place orders via our website;</li>
            <li>To receive enquiries from you through the website about our products and events;</li>
            <li>For improvement and maintenance of our website and to provide technical support for our website;</li>
            <li>To ensure the security of our website;</li>
            <li>To recognise you when you return to our website, to store information about your preferences, and to allow us to customise the website according to your individual interests; and</li>
            <li>To evaluate your visit to the website and prepare reports or compile statistics to understand the type of people who use our website, how they use our website and to make our website more intuitive. Such details will be anonymised as far as reasonably possible and you will not be identifiable from the information collected.</li>
        </ul>
        
        <p>Please see sections 2.6 and 2.7 for more details about how we use your personal information.</p>
        
        <p>(c) <strong>A word about cookies</strong></p>
        <ul>
            <li>(i) Some pages on our website use cookies, which are small files placed on your internet browser when you visit our website. We use cookies in order to offer you a more tailored experience in the future, by understanding and remembering your particular browsing preferences.</li>
            <li>(ii) Where we use cookies on our website, you may block these at any time. However, if you use your browser settings to block all cookies (including essential cookies), you may not be able to access all or parts of our website or to use all the functionality provided through our website.</li>
            <li>(iii) For detailed information on the cookies we use and the purposes for which we use them and how to manage your cookies preferences, please refer to our cookies notice here.</li>
        </ul>
        
        <h4>2.2. Customers, and employees of business customers</h4>
        <p>(a) <strong>What personal information we collect about you</strong><br />
        We, or third parties on our behalf, may collect and use any of the following information about you:</p>
        <ul>
            <li>Your name;</li>
            <li>Your postal address;</li>
            <li>Your email address;</li>
            <li>Your telephone number;</li>
            <li>Information provided when you correspond with us;</li>
            <li>Any updates to information provided to us;</li>
            <li>Information about the products, events and associated services we provide to you:
                <ul>
                    <li>(A) Information needed to provide our products, events and services to you (including your order details, order history, booking details, booking history, and payment details);</li>
                    
        <li>(B) Customer services information; and</li>
                    <li>(C) Customer relationship management and marketing information; and</li>
                </ul>
            </li>
            <li>Information you provide to help us provide you with improved products and events, for example, if we ask you to fill in a survey or questionnaire.</li>
        </ul>

        <p>(b) <strong>How we use your personal information</strong><br />
        We will collect, use and store the personal information listed above for the following reasons:</p>
        <ul>
            <li>To provide you with our products, events and services;</li>
            <li>To facilitate deliveries of products, events and services to you;</li>
            <li>To deal with any enquiries or issues you have about our products, events and services that you request from us;</li>
            <li>To send you certain communications (including by email or post) about our products, events and services such as administrative messages (for example, setting out changes to our terms and conditions and keeping you informed about our fees and charges);</li>
            <li>To carry out statistical analysis and market research on people who may be interested in our products, events and services;</li>
            <li>Where you have consented to receive such information, to contact you with information about our products, events and services or the products and services of our suppliers; and</li>
            <li>If it is in our legitimate interests for business development and marketing purposes, to enable our third-party suppliers to serve advertisements to you about our products and services which are similar to those you have already purchased or enquired about or we otherwise feel may be of interest to you.</li>
        </ul>

        <p>Please see sections 2.6 and 2.7 for more details about how we use your personal information.</p>

        <p>(c) <strong>Source of personal information.</strong> You will share some of your personal information with us in the course of using our website. We may also receive some of your personal information from third parties, such as the suppliers who provide our e-commerce functionality and through whom we receive the personal data that you enter into fields within that part of our website.</p>

        <p>(d) <strong>Information we need to provide services to you.</strong> We need certain types of personal information so that we can provide services to you and perform contractual and other legal obligations that we have to you. If you do not provide us with such personal information, or if you ask us to delete it, you may no longer be able to access our events or receive our products.</p>

        <h4>2.3. Prospective customers to whom we send marketing communications</h4>
        <p>(a) We, or third parties on our behalf, may collect and use any of the following information about you:</p>
        <ul>
            <li>Your name including your title;</li>
            <li>Your postal address;</li>
            <li>Your email address;</li>
            <li>Your telephone number; and</li>
            <li>Information about your preferences.</li>
        </ul>

        <p>(b) <strong>How we use your personal information</strong><br />
        We will collect, use and store the personal information listed above, if you have consented, to contact you (including by telephone, email or post) with information about our products and services which either you request or which we feel will be of interest to you (including newsletters).</p>

        <p>Please see sections 2.6 and 2.7 for more details about how we use your personal information.</p>

        <p>(c) <strong>Source of personal information.</strong> You may share some of your personal information with us in the course of using our website or where we met you in person at an event. We may also receive some of your personal information from third parties, such as marketing agencies and suppliers who host forms on our website.</p>

        <h4>2.4. People who contact us with enquiries</h4>
        <p>(a) We, or third parties on our behalf, may collect and use any of the following information about you:</p>
        <ul>
            <li>Your name including your title;</li>
            <li>Your postal address;</li>
            <li>Your email address;</li>
            <li>Your telephone number;</li>
            <li>Information provided when you correspond with us; and</li>
            <li>Any updates to information provided to us.</li>
        </ul>

        <p>(b) <strong>How we use your personal information</strong><br />
        We will collect, use and store the personal information listed above to deal with any enquiries or issues you have about our products and services, including any questions you may have about how we collect, store and use your personal information, or any requests made by you for a copy of the information we hold about you. If we do not have a contract with you, we may process your personal information for these purposes where it is in our legitimate interests for customer services purposes.</p>

        <p>Please see sections 2.6 and 2.7 for more details about how we use your personal information.</p>

        <h4>2.5. Our suppliers, and employees of our suppliers</h4>
        <p>(a) We, or third parties on our behalf, may collect and use any of the following information about you:</p>
        <ul>
            <li>Your name including your title;</li>
            <li>Work contact information (phone number, postal address, mailing address, email address);</li>
            <li>Your job title;</li>
            <li>Information provided when you correspond with us;</li>
            <li>Any updates to information provided to us;</li>
            <li>Personal information we collect about you from third party sources such as LinkedIn;</li>
            <li>Details of compensation, expense claims and bank details; and</li>
            <li>Information required to access our systems and applications (such as system IDs).</li>
        </ul>

        <p>(b) <strong>How we use your personal information</strong><br />
        We will collect, use and store the personal information listed above for the following reasons:</p>
        <ul>
            <li>To enable us to purchase and receive products and services from you (including supplier due diligence, payment and expense reporting and financial audits);</li>
            <li>To deal with enquiries from you;</li>
            <li>To assess you or your employer’s suitability to work for us; and</li>
            <li>For security of BALL TECHNOLOGY LTD T/A WORGAN GAMES facilities.</li>
        </ul>

        <p>Please see sections 2.6 and 2.7 for more details about how we use your personal information.</p>

        <p>(c) <strong>Source of personal information.</strong> We may receive some of your personal information from third party sources, such as your employer or your employer’s company website. We may also collect this personal information from publicly-available sources, such as LinkedIn.</p>

        <p>(d) <strong>Information we need to receive services from you.</strong> Please note that we need certain types of personal information so that you or your employer can provide services to us. If you do not provide us with such personal information, or if you or your employer ask us to delete it, you may no longer be able to provide services to us.</p>

        <h4>2.6. Everyone</h4>
        <p>Whatever our relationship with you is, we may also collect, use and store your personal information for the following additional reasons:</p>
        <ul>
            <li>To deal with any enquiries or issues you have about how we collect, store and use your personal information, or any requests made by you for a copy of the information we hold about you. If we do not have a contract with you, we may process your personal information for these purposes where it is in our legitimate interests;</li>
            <li>For internal corporate reporting, business administration, ensuring adequate insurance coverage for our business, ensuring the security of company facilities, research and development, and to identify and implement business efficiencies. We may process your personal information for these purposes where it is in our legitimate interests to do so;</li>
            <li>To comply with any procedures, laws and regulations which apply to us – this may include where we reasonably consider it is in our legitimate interests or the legitimate interests of others to comply, as well as where we are legally required to do so; and</li>
            <li>To establish, exercise or defend our legal rights – this may include where we reasonably consider it is in our legitimate interests or the legitimate interests of others, as well as where we are legally required to do so.</li>
        </ul>

        <h4>2.7. Further processing</h4>
        <p>Before using your personal information for any purposes which fall outside those set out in this section 2, we will undertake an analysis to establish if our new use of your personal information is compatible with the purposes set out in this section 2. Please contact us using the details in section 13 if you want further information on the analysis we will undertake.</p>

        <h3>3. Legal basis for use of your personal information</h3>
        <p>3.1. We consider that the legal bases for using your personal information as set out in this privacy notice are as follows:</p>
        <ul>
            <li>(a) Our use of your personal information is necessary to perform our obligations under any contract with you (for example, to fulfil an order which you place with us, to comply with the terms of use of our website which you accept by browsing our website and/or to comply with our contract to provide services to or receive services from you;</li>
            <li>(b) Our use of your personal information is necessary for complying with our legal obligations (for example, for health and safety purposes); or</li>
            <li>(c) Where neither (a) nor (b) apply, use of your personal information is necessary for our legitimate interests or the legitimate interests of others (for example, to ensure the security of our website). Our legitimate interests are to:
                <ul>
                    <li>(i) Run, grow and develop our business;</li>
                    <li>(ii) Operate our website;</li>
                    <li>(iii) Select appropriately skilled and qualified suppliers;</li>
                    <li>(iv) Ensure a safe environment for our workforce, visitors and suppliers;</li>
                    <li>(v) Carry out marketing, market research and business development;</li>
                    <li>(vi) Place, track and ensure fulfilment of orders with our suppliers and customers; and</li>
                    <li>(vii) For internal administrative purposes.</li>
                </ul>
            </li>
        </ul>

        <p>If we rely on our (or another person’s) legitimate interests for using your personal information, we will undertake a balancing test to ensure that our (or the other person’s) legitimate interests are not outweighed by your interests or fundamental rights and freedoms which require protection of the personal information. You can ask us for information on this balancing test by using the contact details at section 13.</p>

        <p>3.2. We may process your personal information in some cases for marketing purposes on the basis of your consent (which, if you are based in the EU, you may withdraw at any time after giving it, as described below).</p>

        <p>3.3. If you are based in the EU and we rely on your consent for us to use your personal information in a particular way, but you later change your mind, you may withdraw your consent by contacting us at <a href="mailto:info@worgangames.com">info@worgangames.com</a> and we will stop doing so. However, if you withdraw your consent, this may impact the ability for us to be able to provide our products, events and associated services to you (for example, if those services require use of your special categories of data such as health information).</p>

        <h3>4. How and why we share your personal information with others</h3>
        <p>4.1. We will share your personal information with the following third parties or categories of third parties:</p>
        <ul>
            <li>(a) The third-party provider which hosts our e-commerce solution and may access personal data stored within that solution when providing hosting and support services;</li>
            <li>(b) We may share anonymised and aggregated statistical information with our suppliers to demonstrate what interest there has been in any marketing campaigns we have assisted our suppliers in carrying out;</li>
            <li>(c) Our other service providers and sub-contractors, including payment processors, information management platform providers, utility providers, suppliers of technical and support services, insurers, logistic providers, and cloud service providers;</li>
            <li>(d) Public agencies and the emergency services;</li>
            <li>(e) Companies that assist in our marketing, advertising and promotional activities, such as marketing automation, lead generation and web analytics providers; and</li>
            <li>(f) Analytics and search engine providers that assist us in the improvement and optimisation of our website.</li>
        </ul>

        <p>4.2. Any third parties with whom we share your personal information are limited by law in their ability to use your personal information which we control. We will always ensure that any third parties with whom we share your personal information are subject to privacy and security obligations consistent with this privacy notice and applicable laws.</p>

        <p>4.3. We will also disclose your personal information to third parties:</p>
        <ul>
            <li>(a) Where it is in our legitimate interests to do so to run, grow and develop our business:
                <ul>
                    <li>(i) If we sell or buy any business or assets, we may disclose your personal information to the prospective seller or buyer of such business or assets;</li>
                    <li>(ii) If substantially all of our or any of our affiliates’ assets are acquired by a third party, in which case personal information held by us will be one of the transferred assets;</li>
                </ul>
            </li>
            <li>(b) If we are under a duty to disclose or share your personal information in order to comply with any legal obligation, any lawful request from government or law enforcement officials and as may be required to meet national security or law enforcement requirements or prevent illegal activity;</li>
            <li>(c) In order to enforce or apply our terms of use, our terms and conditions for customers or any other agreement or to respond to any claims, to protect our rights or the rights of a third party, to protect the safety of any person or to prevent any illegal activity; or</li>
            <li>(d) To protect the rights, property, or safety of BALL TECHNOLOGY LTD T/A WORGAN GAMES, our staff, our customers or other persons. This may include exchanging personal information with other organisations for the purposes of fraud protection.</li>
        </ul>

        <p>4.4. We may also disclose and use anonymised, aggregated reporting and statistics about users of our website or our goods and services for the purpose of internal reporting or reporting to third parties, and for our marketing and promotion purposes. None of these anonymised, aggregated reports or statistics will enable our users to be personally identified.</p>

        <p>4.5. Save as expressly detailed above, we will never share, sell or rent any of your personal information to any third party without notifying you and, where necessary, obtaining your consent. If you have given your consent for us to use your personal information in a particular way, but later change your mind, you should contact us and we will stop doing so.</p>

        <h3>5. How long we store your personal information</h3>
        <p>We keep your personal information for no longer than necessary for the purposes for which the personal information is processed. The length of time for which we retain personal information depends on the purposes for which we collect and use it and/or as required to comply with applicable laws and to establish, exercise or defend our legal rights.</p>

        <h3>6. Your rights</h3>
        <p>6.1. You have certain rights in relation to your personal information. If you would like further information in relation to these or would like to exercise any of them, please contact us via email at <a href="mailto:info@worgangames.com">info@worgangames.com</a> at any time.</p>

        <p>6.2. You have the following rights:</p>
        <ul>
            <li>(a) <strong>Right of access.</strong> You have a right of access to any personal information we hold about you. You can ask us for a copy of your personal information; confirmation as to whether your personal information is being used by us; details about how and why it is being used; and details of the safeguards which are in place if we transfer your information outside of the United Kingdom or the European Economic Area (“EEA”).</li>
            <li>(b) <strong>Right to update your information.</strong> You have a right to request an update to any of your personal information which is out of date or incorrect.</li>
            <li>(c) <strong>Right to delete your information.</strong> You have a right to ask us to delete any personal information which we are holding about you in certain specific circumstances. You can ask us for further information on these specific circumstances by contacting us using the details in section 13. We will pass your request onto other recipients of your personal information unless that is impossible or involves disproportionate effort. You can ask us who the recipients are, using the contact details in section 13.</li>
            <li>(d) <strong>Right to restrict use of your information:</strong> You have a right to ask us to restrict the way that we process your personal information in certain specific circumstances. You can ask us for further information on these specific circumstances by contacting us using the details in section 13. We will pass your request onto other recipients of your personal information unless that is impossible or involves disproportionate effort. You can ask us who the recipients are using the contact details in section 13.</li>
            <li>(e) <strong>Right to stop marketing:</strong> You have a right to ask us to stop using your personal information for direct marketing purposes. If you exercise this right, we will stop using your personal information for this purpose.</li>
            <li>(f) <strong>Right to data portability:</strong> You have a right to ask us to provide your personal information to a third-party provider of services. This right only applies where we use your personal information on the basis of your consent or performance of a contract; and where our use of your information is carried out by automated means.</li>
            <li>(g) <strong>Right to object:</strong> You have a right to ask us to consider any valid objections which you have to our use of your personal information where we process your personal information on the basis of our or another person’s legitimate interest.</li>
        </ul>

        <p>6.3. We will consider all such requests and provide our response within a reasonable period (and in any event within one month of your request unless we tell you we are entitled to a longer period under applicable law). Please note, however, that certain personal information may be exempt from such requests in certain circumstances, for example if we need to keep using the information to comply with our own legal obligations or to establish, exercise or defend legal claims.</p>

        <p>6.4. If an exception applies, we will tell you this when responding to your request. We may request you provide us with information necessary to confirm your identity before responding to any request you make.</p>

        <h3>7. Children</h3>
        <p>7.1. You must be aged 18 or over to purchase products or services from us. Our website and services are not directed at children and we do not knowingly collect any personal information from children.</p>
        <p>7.2. If you are a child and we learn that we have inadvertently obtained personal information from you from our websites, or from any other source, then we will delete that information as soon as possible.</p>
        <p>7.3. Please contact us at <a href="mailto:info@worgangames.com">info@worgangames.com</a> if you are aware that we may have inadvertently collected personal information from a child.</p>

        <h3>8. Marketing</h3>
        <p>8.1. We may collect and use your personal information for undertaking marketing by email, telephone, post and through social media and targeting advertising.</p>
        <p>8.2. We may send you certain marketing communications (including electronic marketing communications) if it is in our legitimate interests to do so for marketing and business development purposes or where you have consented to receive such electronic marketing information.</p>
        <p>8.3. However, we will always obtain your consent to direct marketing communications where we are required to do so by law and if we intend to disclose your personal information to any third party for such marketing.</p>
        <p>8.4. If you wish to stop receiving marketing communications, you can contact us by email at <a href="mailto:info@worgangames.com">info@worgangames.com</a>.</p>

        <h3>9. Where we may transfer your personal information</h3>
        <p>9.1. Your personal information may be used, stored and/or accessed by staff operating outside the EEA working for us, other members of our group or suppliers. If we provide any personal information about you to any such non-EEA members of our group or suppliers, we will take appropriate measures to ensure that the recipient protects your personal information adequately in accordance with this privacy notice. These measures may include the following permitted in Articles 45 and 46 of the General Data Protection Regulation:</p>
        <ul>
            <li>(a) In the case of US-based entities, entering into European Commission approved standard contractual arrangements with them, or ensuring they have signed up to the EU-US Privacy Shield (see further <a href="https://www.privacyshield.gov/welcome" target="_blank">https://www.privacyshield.gov/welcome</a>); or</li>
            <li>(b) In the case of entities based in other countries outside the EEA, entering into European Commission approved standard contractual arrangements with them.</li>
        </ul>
        <p>9.2. Further details on the steps we take to protect your personal information in these cases is available from us on request by contacting us by email at <a href="mailto:info@worgangames.com">info@worgangames.com</a> at any time.</p>

        <h3>10. Risks and how we keep your personal information secure</h3>
        <p>10.1. The main risk of our processing of your personal information is if it is lost, stolen or misused. This could lead to your personal information being in the hands of someone else who may use it fraudulently or make public, information that you would prefer to keep private.</p>
        <p>10.2. For this reason, we are committed to protecting your personal information from loss, theft and misuse. We take all reasonable precautions to safeguard the confidentiality of your personal information, including through use of appropriate organisational and technical measures such as training our workforce, using up-to-date anti-virus software, maintaining secure passwords for systems access including use of double authentication (where possible), encrypting data and maintaining the physical security of our premises.</p>
        <p>10.3. In the course of provision of your personal information to us, your personal information may be transferred over the internet. Although we make every effort to protect the personal information which you provide to us, the transmission of information over the internet is not completely secure. As such, you acknowledge and accept that we cannot guarantee the security of your personal information transmitted to our website and that any such transmission is at your own risk. Once we have received your personal information, we will use strict procedures and security features to prevent unauthorised access to it.</p>

        <h3>11. Links to other websites</h3>
        <p>Our website may contain hyperlinks to websites that are not operated by us. These hyperlinks are provided for your reference and convenience only and do not imply any endorsement of the activities of such third-party websites or any association with their operators. This privacy policy only applies to the personal information that we collect or which we receive from third party sources, and we cannot be responsible for personal information about you that is collected and stored by third parties. Third party websites have their own terms and conditions and privacy policies, and you should read these carefully before you submit any personal information to these websites. We do not endorse or otherwise accept any responsibility or liability for the content of such third-party websites or third-party terms and conditions or policies.</p>

        <h3>12. Changes to our privacy notice</h3>
        <p>We may update our privacy notice from time to time. Any changes we make to our privacy notice in the future will be posted on this page and, where appropriate, notified to you by post or email. Please check back frequently to see any updates or changes to our privacy notice.</p>

        <h3>13. Further questions and how to make a complaint</h3>
        <p>13.1. If you have any queries or complaints about our collection, use or storage of your personal information, or if you wish to exercise any of your rights in relation to your personal information, please contact <a href="mailto:info@worgangames.com">info@worgangames.com</a>. We will investigate and attempt to resolve any such complaint or dispute regarding the use or disclosure of your personal information.</p>
        <p>13.2. In accordance with Article 77 of the General Data Protection Regulation, you may also make a complaint to the Information Commissioner’s Office, or the data protection regulator in the country where you usually live or work, or where an alleged infringement of the General Data Protection Regulation has taken place. Alternatively, you may seek a remedy through the courts if you believe your rights have been breached.</p>
        
        <p>The practices described in this privacy notice statement are current as of Monday 17th June 2024.</p>
      </Container>
  );
};

export default Privacy;
