import {
  Box, Button, Container, Flex, Grid,
  Heading,
  Section, Text
} from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { routesConfig } from "../../../App";
import BreathingAnimation from "../inhale/BreathingAnimation";
import styles from "./HowToPlayGoose.module.scss";
import Tracker from "/shared/images/GooseTracker.webp";
import Hand from "/shared/images/hand.webp";
import NoSlam from "/shared/images/NoSlam.webp";
import Setup from "/shared/images/Setup.webp";
import Slam from "/shared/images/Slam.webp";
import Swap from "/shared/images/swap.webp";

const HowToPlayJinx: React.FC = () => {
  return (
    <Box className={styles.HowToPlayGooseContainer}>
      <Box className={styles.Cover}></Box>

      <Container pb="9" className={styles.ContentContainer}>
        <Flex justify="center" flexGrow={"1"}>
          <Heading as="h1" size={"9"} my="5">
            How to Play
          </Heading>
        </Flex>
        <Flex justify="center" flexGrow={"1"}>
          <Text size={"6"}>The player with the lowest overall score wins.</Text>
        </Flex>
        <Section>
          <Flex justify="center" align="center">
              <img src={Setup} alt="Cards setup graphic" height={"400px"} />
          </Flex>
        </Section>

        <Flex direction={{ initial: "column-reverse", md: "row" }}>
          <Flex align="center" justify="center" className="halfWidth">
              <Link to={routesConfig["SNEAKY_GOOSE_SCORE_TRACKER"].path}>
                <img src={Tracker} alt="Score tracker logo" width={"300px"} />
              </Link>
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Setup
            </Heading>
            <Text mt="3">
              <ul>
                <li>1. Get a pen + paper OR use our online goose tracker:</li>

                <li>
                  2. Decide on how many rounds you want to play (5 recommended)
                </li>
              </ul>
              <Link to={routesConfig["SNEAKY_GOOSE_SCORE_TRACKER"].path}>
                <Button mt="5" size="4">
                  Goose Score Tracker{" "}
                </Button>{" "}
              </Link>
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }}>
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Round Setup
            </Heading>
            <Text mt="3">
              <ol>
                <li>
                  1. Shuffle the deck and deal each player four cards, placed
                  face-down, arranged into a two-by-two grid. Players must not
                  look at any cards!
                </li>
                <li>
                  2. The remaining cards are placed face down in the center to
                  form a draw pile.
                </li>
                <li>
                  3. All players will now look at their bottom two cards (the
                  ones closest to them) one time only. Try to remember your
                  cards!
                </li>
                <li>
                  4. The player to the left of the dealer goes first and picks
                  the top card from the draw pile with play continuing in a
                  clockwise direction.
                </li>
              </ol>
            </Text>
          </Flex>
          <Flex align={"center"} justify="center">
              <img src={Hand} alt="Card grid setup" width={"300px"} />
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }}>
          <Flex align={"center"} justify="center" className="halfWidth">
              <img src={Swap} alt="Swapping cards example" width={"300px"} />
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Your Turn
            </Heading>
            <Text mt="3">
              On your turn you may do one of three things:
              <ol>
                <li>
                  1. Draw the top card from the draw pile (look at it) and swap
                  it with one of your own cards, placing your own card into the
                  discard pile.
                </li>
                <li>
                  2. Take the top card from the discard pile and swap it with
                  one of your own cards.
                </li>
                <li>
                  3. Draw the top card from the draw pile and play it face-up
                  onto the discard pile and carry out the card's special ability
                  (if it has one).
                </li>
              </ol>
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }}>
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Slamming
            </Heading>
            <Text mt="3">
              If at any point a player sees a goose face-up on the discard pile
              and thinks they know where a face-down goose of the same type
              (e.g. Magic Goose) is, they may slam their hand down on the
              face-down goose.(If multiple people slam the same card. Only the
              first person to touch the card becomes the slammer)
            </Text>
            <Text mt="3">
              As soon as the player has touched a card in an attempt to slam it,
              then the card MUST be turned over.
            </Text>
          </Flex>
          <Flex align={"center"} justify="center">
              <img src={Slam} alt="Slamming example" width={"300px"} />
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }}>
          <Flex align={"center"} justify="center" className="halfWidth">
              <img src={NoSlam} alt="Successful slam graphic" width={"500px"} />
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Slamming Outcomes
            </Heading>
            <Text mt="3">
              <ol>
                <li>
                  1. A player successfully slams one of their own cards = That
                  card is placed face-up on the discard pile.
                </li>
                <li>
                  2. A player successfully slams another player's card = That
                  card is placed face-up on the discard pile and the slamming
                  player chooses one of their cards to donate to the slammed
                  player.
                </li>
                <li>
                  3. A player unsuccessfully slams a card = The card is put back
                  and the slamming player receives another face-down card from
                  the draw pile to add to their cards.
                </li>
              </ol>
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }}>
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Honk!
            </Heading>
            <Text mt="3">
              If a player believes that they have the lowest score, they may
              "HONK" at the start of their turn to end the round. When a player
              Honks, all players must immedietly turn over their cards so that
              their points can be added up.
            </Text>
            <Text mt="3">
              If the honker is the lowest scorer for that round, they recieve an
              additional -10 points.
            </Text>
            <Text mt="3">
              If the honker is not the lowest scorer, they recieve an additional
              +10 points.
            </Text>
            <Text mt="3">
              If a player has no cards remaining in their card grid at the start
              of their turn, the round automatically ends.
            </Text>
            <Text mt="3">
              Each player's score for the round is added to their total game
              score. You can either use a pen and paper, or our online score
              tracker (insert button) to keep track of scores.
            </Text>
            <Text mt="3">
              Feel youre falling behind the gaggle? Fear not, as when tallying
              the score, if a player's game score hits a multiple of 50, it is
              immediately halved.
            </Text>
          </Flex>
          <Flex pl="7" align={"center"} justify="center">
              <BreathingAnimation />
          </Flex>
        </Grid>
      </Container>
    </Box>
  );
};

export default HowToPlayJinx;
