import { Flex, Heading } from "@radix-ui/themes";
import ContactUs from "../../shared/components/contact-us-form/contactUs";
import FaqAccordion from "../../shared/components/faq-accordian/faq-accordian";
import Header from "../../shared/components/header/header";


const Contact = () => {
  return (
    <>
      <Header
        heading="Help and Contact"
        subtext="Our team is here to help with any questions or issues. We're committed to providing the best customer experience."
        blur={false}
        thin={true}
      />

      <Flex
        direction="column"
        align="center"
        justify="center"
      >
        <ContactUs />
      </Flex>
      
    </>
  );
};

export default Contact;
