import { PlusCircledIcon, StarFilledIcon } from "@radix-ui/react-icons";
import { Card, Flex, Heading, Link, Text } from "@radix-ui/themes";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useCartStore } from "../../../state";
import styles from "./ProductCard.module.scss";

interface ProductCardProps {
  product: any;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const addOneToCart = useCartStore((state) => state.addOneToCart);
  const navigate = useNavigate();
  const quickAdd = (event: any) => {
    event.stopPropagation();
    addOneToCart(product.id, product.path);
  };

  return (
    <Flex
      onClick={() => {
        navigate(`/shop/${product?.path.toLowerCase().replaceAll("_", "-")}`);
      }}
      className={classNames("testimonial-card", styles.ProductCard)}
      mb="6"
    >
      <Flex
        direction={"column"}
        justify="between"
        align={"center"}
        height={"100%"}
      >
        <Flex direction={"column"} justify="between" align={"center"}>
          <Flex gap="1" direction={"column"} align="center">
            <img
              width="100%"
              src={product.image}
              alt={`Image of ${product.title}`}
            />

            <Flex direction={"column"} justify="between" align={"center"} p="4" pt="0"> 

            <Flex align={"center"}>
              <StarFilledIcon color="gold" className="w-5 h-5" />
              <StarFilledIcon color="gold" className="w-5 h-5" />
              <StarFilledIcon color="gold" className="w-5 h-5" />
              <StarFilledIcon color="gold" className="w-5 h-5" />
              <StarFilledIcon color="gold" className="w-5 h-5" />
              <Text ml="1">
                {product?.rating} |{" "}
                <span>{product?.reviews?.length} Reviews</span>
              </Text>
            </Flex>
            <Heading as="h1" className={styles.ProductTitle}>
              {product?.title}
            </Heading>
            <Text size={"5"} color="orange">
              <span className={styles.OldPrice}>£{product?.oldPrice}</span>
              <span className={styles.Price}>£{product?.price}</span>
            </Text>
            <Text
              align={"center"}
              size={"5"}
              className={styles.ProductSubTitle}
            >
{product?.subtitle?.length > 70
  ? `${product.subtitle.substring(0, 70)}...`
  : product?.subtitle}            </Text>

        <Link className={styles.QuickAddLink} onClick={(e) => quickAdd(e)}>
          <Flex align="center" gap="1">
            Quick Add
            <PlusCircledIcon />
          </Flex>
        </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductCard;
