import { Box, Flex, Heading } from "@radix-ui/themes";
import styles from "./TopBrass.module.scss";
import CustomCarousel from "../../shared/components/carousel/carousel";
import carouselData from "../../shared/constants/homeCarousel.json";
import MarketingSection from "../../shared/components/marketing-section/MarketingSection";
import ExplainerSection from "../../shared/components/explainer-section/ExplainerSection";
import brassLogo from "/shared/images/TopBrassLogo.webp";
import brassMarketing from "../../shared/constants/brassMarketingData.json";
import HowToPlayTopBrass from "../../TopBrass/components/HowToPlayTopBrass/HowToPlayTopBrass";


const TopBrass = () => {
  return (
    <>
      <Flex className="MainContainer" direction={"column"}>
        <Flex my={"2"} justify={"center"} align={"center"}  direction={{initial:"column", md:"row"}}>
          <img className={styles.noiseLogo} height="50%" src={brassLogo} />
          
          <Flex  justify="center" py={"5"} px={{initial: "1", md: "9" }}>
            <Heading as={"h1"} size={{initial: "8", md: "9" }}>THE FAMILY <br/>FUN MILITARY<br/>CARD GAME</Heading>
          </Flex>
        </Flex>
        
        <MarketingSection
          data={brassMarketing}
        />

        <ExplainerSection
          title="Top Brass"
          description="Outsmart, outrank and outmaneuver the enemy!"
          listItems={["2-6 Players", "Ages 8+", "Strategic and adaptable gameplay"]}
          image="/shared/images/BrassProductGlow.webp"
          productName={"TOP_BRASS"}
        />

       <HowToPlayTopBrass/>
        
        
        <Box className="ComingSoonCarouselContainer">
          <CustomCarousel cards={carouselData} />
        </Box>
        </Flex>
    </>
  );
};

export default TopBrass;
