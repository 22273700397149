import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export const useTrackEvent = () => {
  const location = useLocation(); // Gets the current route

  const trackEvent = (action: string, label: string, value: number | undefined = undefined) => {
    const category = location.pathname;

    ReactGA.event({
      action,
      category,
      label,
      value,
    });
  };

  return trackEvent;
};
