import { Box, Flex, Heading } from "@radix-ui/themes";

import CustomCarousel from "../../shared/components/carousel/carousel";
import EmailSubmission from "../../shared/components/email-submission/email-submission";
import ExplainerSection from "../../shared/components/explainer-section/ExplainerSection";
import MarketingSection from "../../shared/components/marketing-section/MarketingSection";
import carouselData from "../../shared/constants/homeCarousel.json";
import gooseMarketing from "../../shared/constants/sneakyGooseMarketingData.json";
import HowToPlayGoose from "../../SneakyGoose/components/HowToPlayGoose/HowToPlayGoose";

import FaqAccordion from "../../shared/components/faq-accordian/faq-accordian";

const SneakyGoose = () => {
  return (
    <>
      <Flex className="MainContainer" direction={"column"}>
        <Flex
          height={"450px"}
          justify={{ initial: "center", md: "end" }}
          mr={{ initial: "0", md: "9" }}
          pr={{ initial: "0", md: "9" }}
          align={"center"}
        >
          <Heading as="h1" size={"9"} align="center" className="HugeText">
            SNEAKY <br /> GOOSE
          </Heading>
        </Flex>

        <MarketingSection
          
          data={gooseMarketing}
        />

        <ExplainerSection
          title="Sneaky Goose"
          description="Swap, Slam and Outsmart the Gander"
          listItems={["2-5 Players", "30 Minute Playtime", "Ages 8+"]}
          image="/shared/images/GooseBox.webp"
          productName={"SNEAKY_GOOSE"}
        />

        <HowToPlayGoose />

        <EmailSubmission />

        <FaqAccordion
          items={[
            {
              heading: "Which way up are cards on the discard pile?",
              content:
                "All cards played onto the discard pile should be played face up. In your rules leaflet or at the top of the 'How to Play' section of this page, you can see a basic setup image of how the game should look.",
            },
            {
              heading: "I'm slightly confused about the magic goose card?",
              content:
                "Magic goose, when played correctly, is a very powerful card but can seem daunting at first. 'Look at any 1 card' - This means you can look at ANY card you wish: one of your own, someone else's, the top of the draw pile, or even a random card from a different game (although we’re not sure why you would!). 'Swap any 2 cards' - This means you can take ANY card in the play area: one of your own, someone else's, the top of the draw pile, or the top of the discard pile—and swap it with ANY other card in the play area. Just make sure that if you're swapping a card that is face down, the card you place in its position is also face down.",
            },
            {
              heading: "What does the word 'any' mean?",
              content:
                "'Used to refer to one of a number of things or people, when it does not matter which one. For example, 'Blindly swap any card with another.'' - In the event one of the cards (Danger or Magic) requires a player to swap ANY card, it really means any. A card from your grid, another player's grid, or even from the discard and play pile.",
            },
            {
              heading: "Can you play this with 2?",
              content:
                "Absolutely! But you will find that it's a very different game where strategy plays a larger role than memory.",
            },
            {
              heading:
                "Do I have to swap my deck if I'm only playing with 2 people?",
              content:
                "No, you don't. In the case of a 2-player game and you pick a Crazy Goose, you can simply play it into the discard pile without swapping, if you so wish.",
            },
            {
              heading: "All my grid cards are gone, now what?",
              content:
                "In the event you start your turn with an empty card grid (ie you have no cards left), you MUST honk to end the round. Believe it or not, people can still lose the round even with no cards!",
            },
          ]}
        />

        <CustomCarousel cards={carouselData} />
      </Flex>
    </>
  );
};

export default SneakyGoose;
