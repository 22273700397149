import { create } from "zustand";
import { persist } from "zustand/middleware";
import { postRequest } from "./shared/util/apiHelpers";

export type CartItem = {
  id: string;
  quantity: number;
  path: string;
};

export type Product = {
  id: string;
  title: string;
  price: number;
  image: string;
  path: string;
  productImages: string[];
  oldPrice: number;
  description: string[];
  detailsItems: string[];
  included: string[];
  comingSoon?: boolean;
  amazonLink?: string;
};

export type ProductsData = {
  [key: string]: Product;
};

const initialProductsData: any = [];

type CartState = {
  items: CartItem[];
  getProductQuantity: (id: string) => number;
  addOneToCart: (id: string, path: string, quantity?: number) => void;
  removeOneFromCart: (id: string) => void;
  deleteFromCart: (id: string) => void;
  setTotalCost: (items: any) => void;
  checkoutLoading: boolean;
  getCheckoutSession: () => Promise<void>;
  cartDrawerOpen: boolean;
  setCartDrawerOpen: (bool: boolean) => void;
  totalCost: number;
  productsData: ProductsData;
  setProductsData: (data: ProductsData) => void;
  emptyCart: () => void;
};

export const useCartStore = create<CartState>()(
  persist(
    (set, get) => ({
      productsData: initialProductsData,
      totalCost: 0,
      setProductsData: (data: ProductsData) => {
        set((state) => ({
          productsData: data
        }))
      },
      items: [],
      checkoutLoading: false,
      getProductQuantity: (id: string) => {
        const item = get().items.find((item) => item.id === id);
        return item ? item.quantity : 0;
      },

    addOneToCart: (id: string, path: string, quantity?: number) => {
  set((state) => {
    let returnValue: any = { items: [] };
    const quantityToAdd = quantity || 1; // Default to 1 if no quantity provided
    const index = state.items.findIndex((item) => item.id === id);
    
    const updatedItems = [...state.items]; // Create a copy of the current items

    if (index !== -1) {
      // If the item exists, update its quantity
      updatedItems[index].quantity += quantityToAdd;
    } else {
      // If item doesn't exist, add it to the items array
      updatedItems.push({ id, quantity: quantityToAdd, path });
    }

    returnValue = { items: updatedItems, cartDrawerOpen: true };
    state.setTotalCost(updatedItems); // Pass the updated items to setTotalCost
    return returnValue;
  });
},

removeOneFromCart: (id: string) => {
  set((state) => {
    const index = state.items.findIndex((item) => item.id === id);
    let returnValue: any = { items: [] };

    const updatedItems = [...state.items]; // Create a copy of the current items
    if (index !== -1) {

      // If quantity is more than 1, decrease it, otherwise remove the item
      if (updatedItems[index].quantity > 1) {
        updatedItems[index].quantity -= 1;
      } else {
        updatedItems.splice(index, 1); // Remove the item if quantity is 1
      }

      returnValue = { items: updatedItems };
    } else {
      returnValue = { items: state.items }; // No changes if the item is not found
    }

    state.setTotalCost(updatedItems); // Pass the updated items to setTotalCost
    return returnValue;
  });
},

deleteFromCart: (id: string) => {
  set((state) => {
    const updatedItems = state.items.filter((item) => item.id !== id);
    state.setTotalCost(updatedItems); // Recalculate total cost after deleting
    return { items: updatedItems };
  });
},

emptyCart: () => {
  set((state) => {
    return { items: [], totalCost: 0};
  });
},

setTotalCost: (items: any) => {
  set((state) => {
    const total = items.reduce((acc: any, item: any) => {
      const product = state.productsData[item.path]; // Find product by path
      if (product) {
        return acc + item.quantity * product.price; // Multiply quantity by price
      }
      return acc; // Ignore items where product isn't found
    }, 0);

    return { totalCost: total };
  });
},


      getCheckoutSession: async () => {
        try {
          set(() => ({
            checkoutLoading: true,
          }));

          const items = get().items;

          const response = await postRequest(`/shop/checkout`, {
            items,
          });

          if (response && response.checkout_url) {
            window.location.href = response.checkout_url;
          } else {
            throw new Error("No checkout URL or Req failed.");
          }
        } catch (error) {
          console.log(error);
        }
        set(() => ({
          checkoutLoading: false,
        }));
      },

      setCartDrawerOpen: (boolean: boolean) => {
        set({ cartDrawerOpen: boolean });
      },
      

      cartDrawerOpen: false,
    }),
    {
      name: "cart-storage", // name of the storage (localStorage key)
      getStorage: () => localStorage, // define the storage type
      partialize: (state) =>
        // Persist everything except productsData
        ({
          items: state.items,
          totalCost: state.totalCost,
          checkoutLoading: state.checkoutLoading,
          cartDrawerOpen: state.cartDrawerOpen,
        }),
    }
  )
);

// -----------------CONTACT STORE------------------
type ContactState = {
  contacted: boolean;
  subject: string;
  name: string;
  email: string;
  companyName: string;
  orderNum: string;
  message: string;
  setSubject: (str: string) => void;
  setName: (str: string) => void;
  setEmail: (str: string) => void;
  setCompanyName: (str: string) => void;
  setOrderNum: (str: string) => void;
  setMessage: (str: string) => void;
  setContacted: (value: boolean) => void;
  submitContactForm: (
    formData: ContactFormData,
    isWholesale?: boolean
  ) => Promise<void>;
};

type ContactFormData = {
  subject?: string;
  name: string;
  email: string;
  companyName?: string;
  orderNum?: string;
  message: string;
};

export const useContactStore = create<ContactState>()((set) => ({
  contacted: false,
  subject: "",
  name: "",
  email: "",
  companyName: "",
  orderNum: "",
  message: "",
  setContacted: (value: boolean) => set({ contacted: value }),
  setSubject: (str: string) => {
    set({ subject: str });
  },
  setName: (str: string) => {
    set({ name: str });
  },
  setEmail: (str: string) => {
    set({ email: str });
  },
  setCompanyName: (str: string) => {
    set({ companyName: str });
  },
  setOrderNum: (str: string) => {
    set({ orderNum: str });
  },
  setMessage: (str: string) => {
    set({ message: str });
  },
  submitContactForm: async (
    formData: ContactFormData,
    isWholesale: boolean = false
  ) => {
    try {
      await postRequest(`/contact`, {
        formData,
        isWholesale,
      });
      set({
        contacted: true,
        subject: "",
        name: "",
        email: "",
        companyName: "",
        orderNum: "",
        message: "",
      });
    } catch (error) {
      console.log(error);
    }
  },
}));

// -----------------NEWSLETTER STORE------------------
type NewsletterState = {
  submitEmail: (email: string) => Promise<void>;
};

export const useNewsletterStore = create<NewsletterState>()((set) => ({
  submitEmail: async (email: string) => {
    try {
      await postRequest(`/newsletter/submit`, { email });
    } catch (error) {
      throw error;
    }
  },
}));

// -----------------TOAST STORE------------------
type ToastState = {
  title:string;
  subtext:string;
  open: boolean;
  setOpen: (bool: boolean) => void;
  triggerToast: (title:string, subtext:string) => void;
};

export const useToastStore = create<ToastState>()((set) => ({
  title:"",
  subtext:"",
  open:false,
  setOpen: (open: boolean) => {
    set({
      open: open,
    });
  },
  triggerToast: (title: string, subtext: string) => {
    set({
      open:true,
      title: title,
      subtext: subtext,
    });
  },
  

}));

// ----------------- SNEAKY GOOSE SCORE TRACKER -----------------
type SneakyGooseState = {
  players: string[];
  roundScores: number[][];
  rounds: number;
  currentRound: number;
  gameStarted: boolean;
  startGame: (names: string[], rounds: number) => void;
  recordScoresForRound: (scoresForThisRound: number[]) => void;
  reset: () => void;
  gameEnded: boolean;
  playerScores: string[];
  halvedScores: number[];
};

export const useSneakyGooseStore = create<SneakyGooseState>()(
  persist(
    (set, get) => ({
      players: [],
      playerScores: [],
      roundScores: [[]],
      rounds: 5,
      currentRound: 1,
      gameStarted: false,
      gameEnded: false,
      halvedScores: [],
      startGame: (names: string[], rounds: number) => {
        set({
          players: names,
          rounds: rounds,
          gameStarted: true,
          roundScores: Array.from({ length: rounds }, () => []),
          halvedScores: [],
        });
      },
      recordScoresForRound: (scoresForThisRound: number[]) => {
        set((state) => {
          const currentRoundIndex = state.currentRound - 1;

          const updatedRoundScores = state.roundScores.map((scores, index) =>
            index === currentRoundIndex ? scoresForThisRound : scores
          );

          const totalScores = state.players.map((_, playerIndex) =>
            updatedRoundScores.reduce(
              (acc, roundScores) => acc + (roundScores[playerIndex] || 0),
              0
            )
          );

          const halvedScores: number[] = [];

          const adjustedTotalScores = totalScores.map((total, index) => {
            if (total % 50 === 0 && total !== 0) {
              halvedScores.push(index);
              return total / 2;
            }
            return total;
          });

          const newRoundScores = updatedRoundScores.map(
            (roundScores, roundIndex) =>
              roundScores.map((score, playerIndex) => {
                if (roundIndex === currentRoundIndex) {
                  const adjustedTotal = adjustedTotalScores[playerIndex];
                  const totalSoFar = totalScores[playerIndex];
                  if (adjustedTotal !== totalSoFar) {
                    const difference = totalSoFar - adjustedTotal;
                    return score - difference;
                  }
                }
                return score;
              })
          );

          const gameEnded = state.currentRound === state.rounds;

          const getFinalScoresPerPlayer = (
            roundScores: number[][]
          ): string[] => {
            const numberOfPlayers = roundScores[0]?.length || 0;

            const playerScores: string[] = new Array(numberOfPlayers).fill("");

            roundScores.forEach((round) => {
              round.forEach((score, playerIndex) => {
                playerScores[playerIndex] +=
                  (playerScores[playerIndex] ? "," : "") + score;
              });
            });

            return playerScores;
          };

          const playerScores = getFinalScoresPerPlayer(newRoundScores);

          return {
            roundScores: newRoundScores,
            gameEnded: gameEnded,
            currentRound: !gameEnded
              ? state.currentRound + 1
              : state.currentRound,
            playerScores: playerScores,
            halvedScores: halvedScores,
          };
        });
      },
      reset: () => {
        set({
          players: [],
          roundScores: [[]],
          rounds: 5,
          currentRound: 1,
          gameStarted: false,
          gameEnded: false,
          playerScores: [],
          halvedScores: [],
        });
      },
    }),
    {
      name: "goose-storage", // name of the storage (localStorage key)
      getStorage: () => localStorage, // define the storage type
    }
  )
);

/// ----------------- PUNCHY KICKY SCORE TRACKER -----------------
type PunchyKickyState = {
  players: string[];
  roundScores: number[][];
  rounds: number;
  currentRound: number;
  gameStarted: boolean;
  startGame: (names: string[]) => void;
  recordScoresForRound: (scoresForThisRound: number[], lastStandingIndex: number | null, knockouts: number[]) => void;
  reset: () => void;
  gameEnded: boolean;
  playerScores: string[];
};

export const usePunchyKickyStore = create<PunchyKickyState>()(
  persist(
    (set, get) => ({
      players: [],
      playerScores: [],
      roundScores: [[]],
      rounds: 5,
      currentRound: 1,
      gameStarted: false,
      gameEnded: false,
      startGame: (names: string[]) => {
        set({
          players: names,
          gameStarted: true,
          roundScores: Array.from({ length: 5 }, () => []),
        });
      },
      recordScoresForRound: (scoresForThisRound: number[], lastStandingIndex: number | null, knockouts: number[]) => {
        set((state) => {
          const currentRoundIndex = state.currentRound - 1;

        
          const updatedScores = scoresForThisRound.map((score, index) => score * 2);

          if (lastStandingIndex !== null) {
            updatedScores[lastStandingIndex] += 2;
          }

          const updatedRoundScores = state.roundScores.map((scores, index) =>
            index === currentRoundIndex ? updatedScores : scores
          );

          const gameEnded = state.currentRound === state.rounds;

          const getFinalScoresPerPlayer = (
            roundScores: number[][]
          ): string[] => {
            const numberOfPlayers = roundScores[0]?.length || 0;
            const playerScores: string[] = new Array(numberOfPlayers).fill("");

            roundScores.forEach((round) => {
              round.forEach((score, playerIndex) => {
                playerScores[playerIndex] +=
                  (playerScores[playerIndex] ? "," : "") + score;
              });
            });

            return playerScores;
          };

          const playerScores = getFinalScoresPerPlayer(updatedRoundScores);

          return {
            roundScores: updatedRoundScores,
            gameEnded: gameEnded,
            currentRound: !gameEnded ? state.currentRound + 1 : state.currentRound,
            playerScores: playerScores,
          };
        });
      },
      reset: () => {
        set({
          players: [],
          roundScores: [[]],
          rounds: 5,
          currentRound: 1,
          gameStarted: false,
          gameEnded: false,
          playerScores: [],
        });
      },
    }),
    {
      name: "punchy-storage", // name of the storage (localStorage key)
      getStorage: () => localStorage, // define the storage type
    }
  )
);