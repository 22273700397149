import {
  CheckIcon,
  ListBulletIcon,
  Pencil2Icon,
  StarFilledIcon,
  StarIcon,
} from "@radix-ui/react-icons";
import { Button, Container, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TestimonialWall from "../../shared/components/testimonial-wall/testimonial-wall";
import { useCartStore } from "../../state";
import styles from "./Product.module.scss";
import { useRef } from "react";

const Product: React.FC = () => {
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const reviewsRef = useRef<HTMLDivElement>(null); // Step 1: Create a reference

  const addQuantity = () => {
    setQuantity(quantity + 1);
  };

  const removeQuantity = () => {
    setQuantity(quantity !== 1 ? quantity - 1 : 1);
  };

  const { productName } = useParams();

  const productsData = useCartStore((state) => state.productsData);
  const addOneToCart = useCartStore((state) => state.addOneToCart);

  const [product, setProduct] = useState<any>();
  const [currentImage, setCurrentImage] = useState();

  useEffect(() => {
    if (productName && productsData) {
      const actualProductName = productName.replaceAll("-", "_").toUpperCase();

      if (productsData[actualProductName]) {
        setProduct(productsData[actualProductName]);
      } else {
        navigate("/shop");
      }
    }
  }, [productName, productsData]);

  useEffect(() => {
    if (product) {
      setCurrentImage(product.productImages[0]);
    }
  }, [product]);

  // Step 3: Scroll to the reviews section
  const scrollToReviews = () => {
    if (reviewsRef.current) {
      reviewsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Flex direction={"column"} className={styles.ProductPageContainer}>
        <Container>
          <Grid columns={{ initial: "1", md: "2" }}>
            <Flex className={styles.ImagesContainer} justify="center" direction={{initial: 'column-reverse', md: "row"}}>
              <Flex className={styles.ThumbImagesContainer} direction={{initial: "row", md: "column"}}>
                {product?.productImages.map((image: any) => (
                  <img
                    className={`${styles.ThumbImage} ${
                      image === currentImage ? styles.Selected : ""
                    }`}
                    src={image}
                    onMouseOver={() => {
                      setCurrentImage(image);
                    }}
                  />
                ))}
              </Flex>
              <img className={styles.MainImage} src={currentImage} />
            </Flex>

            <Flex
              className={styles.ProductDetailsSection}
              direction="column"
              p="9"
            >
              <Heading as="h1" className={styles.ProductTitle}>
                {product?.title}
              </Heading>

              <Text size={"5"} className={styles.ProductSubTitle}>
                {product?.subtitle}
              </Text>

              <Flex mb="6" align={"center"}>
                <StarFilledIcon color="gold" className="w-5 h-5" />
                <StarFilledIcon color="gold" className="w-5 h-5" />
                <StarFilledIcon color="gold" className="w-5 h-5" />
                <StarFilledIcon color="gold" className="w-5 h-5" />
                <StarFilledIcon color="gold" className="w-5 h-5" />
                <Text ml="1">
                  {product?.rating} |{" "}
                  <span
                    className={styles.ReviewsButton} // Step 5: Add a class to the button
                    onClick={scrollToReviews} // Step 4: Attach the scroll function
                  >
                    {product?.reviews?.length} Reviews
                  </span>
                </Text>
              </Flex>

              <Text size={"5"} color="orange">
                <span className={styles.OldPrice}>£{product?.oldPrice}</span>
                <span className={styles.Price}>£{product?.price}</span>
              </Text>

              <Flex direction={"column"} mt="6">
                <Text size={"5"}>♻️ Planet Friendly Games</Text>
                <Text>
                  At Worgan Games we're committed to sustainability, creating
                  all of our card games through a carbon-neutral manufacturing
                  processes, using vegan-friendly glue and ink, and responsibly
                  sourced materials. Enjoy your game knowing it’s made with the
                  planet in mind!
                </Text>
              </Flex>

              <Flex mt="6" width={"100%"}>
                <Flex direction={{ xs: "row"}} align="center" width={"100%"}>
                  <Flex>
                <Button
                  size={{initial: "2", xs: "4"}}
                  className={styles.QuantityButton}
                  onClick={() => removeQuantity()}
                >
                  -
                </Button>
                <Flex px="2" className={styles.QuantityAmount}>
                  {quantity}
                  <Button
                  size={{initial: "2", xs: "4"}}
                  className={styles.QuantityButton}
                    onClick={() => addQuantity()}
                  >
                    +
                  </Button>
                </Flex>
                    
                  </Flex>
                  <Button
                  size={{initial: "2", xs: "4"}}
                  color={"orange"}
                    className={styles.AddButton}
                    onClick={() => {
                      addOneToCart(product?.id!, product?.path!, quantity);
                    }}
                  >
                    ADD TO CART
                  </Button>
                </Flex>
              </Flex>
              <Text color="gray" size={"2"}>
                Free Delivery | Delivery within 1-2 days | Full refund on
                returns within 30 days
              </Text>
            </Flex>
          </Grid>

          <Flex direction={"column"}>
            <Flex align={"center"} mb="6">
              <Pencil2Icon color="darkorange" className="h-7 w-7" />{" "}
              <Text ml="2" size={"7"}>
                Product Description:
              </Text>
            </Flex>
            {product?.description.map((descParagraph: any) => (
              <>
                <Text className={styles.ProductDescription}>
                  {descParagraph}
                </Text>{" "}
                <br />
              </>
            ))}

            <Flex align={"center"} my="6">
              <CheckIcon color="darkorange" className="h-7 w-7" />{" "}
              <Text ml="2" size={"7"}>
                Product Details:
              </Text>
            </Flex>
            {product?.detailsItems.map((item: string) => (
              <Text className={styles.ProductDescription}>{item}</Text>
            ))}

            {/* Reviews Section */}
            <Flex ref={reviewsRef} align={"center"} my="6">
              <StarIcon color="darkorange" className="h-7 w-7" />{" "}
              <Text ml="2" size={"7"}>
                Reviews:
              </Text>
            </Flex>
          </Flex>
          <TestimonialWall reviews={product?.reviews} />
        </Container>
      </Flex>
    </>
  );
};

export default Product;
