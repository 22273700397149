import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Heading,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useState } from "react";
import { useSneakyGooseStore } from "../../state";
import styles from "./ScoreTracker.module.scss";
import * as Slider from "@radix-ui/react-slider";
import React from "react";
import { routesConfig } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import goose from "/shared/images/GooseTracker.webp";

const rankedBirds = ["🦢", "🦆", "🦩", "🦜", "🦉", "🐥"];

const SneakyGooseScoreTracker = () => {
  const navigate = useNavigate();

  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const [name4, setName4] = useState("");
  const [name5, setName5] = useState("");
  const [name6, setName6] = useState("");
  const [score1, setscore1] = useState<number | null>(null);
  const [score2, setscore2] = useState<number | null>(null);
  const [score3, setscore3] = useState<number | null>(null);
  const [score4, setscore4] = useState<number | null>(null);
  const [score5, setscore5] = useState<number | null>(null);
  const [score6, setscore6] = useState<number | null>(null);

  const [honkerIndex, setHonkerIndex] = useState<number | null>(null);

  const [rounds, setRounds] = useState(5);

  const playerNames = [
    {
      value: name1,
      setter: setName1,
      placeholder: "Enter Player 1's Name",
      required: true,
      score: score1,
      scoreSetter: setscore1,
    },
    {
      value: name2,
      setter: setName2,
      placeholder: "Enter Player 2's Name",
      required: true,
      score: score2,
      scoreSetter: setscore2,
    },
    {
      value: name3,
      setter: setName3,
      placeholder: "Enter Player 3's Name (Optional)",
      score: score3,
      scoreSetter: setscore3,
    },
    {
      value: name4,
      setter: setName4,
      placeholder: "Enter Player 4's Name (Optional)",
      score: score4,
      scoreSetter: setscore4,
    },
    {
      value: name5,
      setter: setName5,
      placeholder: "Enter Player 5's Name (Optional)",
      score: score5,
      scoreSetter: setscore5,
    },
    {
      value: name6,
      setter: setName6,
      placeholder: "Enter Player 6's Name (Optional)",
      score: score6,
      scoreSetter: setscore6,
    },
  ];

  const handleScoreChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = e.target.value.trim();
    const { scoreSetter } = playerNames[index];

    if (newValue === "" || isNaN(Number(newValue))) {
      scoreSetter(null);
    } else {
      let numericValue = Number(newValue);
      if (numericValue >= -12 && numericValue <= 100) {
        scoreSetter(numericValue);
      } else if (numericValue < -12) {
        scoreSetter(-12);
      } else if (numericValue > 100) {
        scoreSetter(100);
      }
    }
  };

  const handleExit = () => {
    reset();
    setHonkerIndex(null);
    navigate(routesConfig["SNEAKY_GOOSE"].path);
  };

  const handleReset = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to exit? All progress will be lost."
    );
    if (userConfirmed) {
      reset();
      setName1("");
      setName2("");
      setName3("");
      setName4("");
      setName5("");
      setName6("");
      setHonkerIndex(null);
    }
  };

  const handleSubmitScores = () => {
    if (honkerIndex === null) {
      alert("Please select a Honker before submitting the scores.");
      return;
    }

    const scores = [score1, score2, score3, score4, score5, score6];
    const hasEmptyField = scores
      .slice(0, players.length)
      .some((score) => score === null);

    if (hasEmptyField) {
      alert("Please enter a score for all players before submitting.");
    } else {
      const validScores = scores
        .slice(0, players.length)
        .filter((score): score is number => score !== null);

      const lowestScore = Math.min(...validScores);
      const lowestScoreCount = validScores.filter(
        (score) => score === lowestScore
      ).length;

      if (honkerIndex !== null) {
        if (validScores[honkerIndex] === lowestScore) {
          if (lowestScoreCount > 1) {
            validScores[honkerIndex] -= 5;
          } else {
            validScores[honkerIndex] -= 10;
          }
        } else {
          validScores[honkerIndex] += 10;
        }
      }

      recordScoresForRound(validScores);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      setscore1(null);
      setscore2(null);
      setscore3(null);
      setscore4(null);
      setscore5(null);
      setscore6(null);
      setHonkerIndex(null);
    }
  };

  const handleStart = () => {
    const names = [name1, name2, name3, name4, name5, name6];
    const validNames = names.filter((name) => name.trim() !== "");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (validNames.length > 1) {
      startGame(validNames, rounds);
    } else {
      alert("Please enter more than one player");
    }
  };

  const startGame = useSneakyGooseStore((state) => state.startGame);
  const gameStarted = useSneakyGooseStore((state) => state.gameStarted);
  const currentRound = useSneakyGooseStore((state) => state.currentRound);
  const players = useSneakyGooseStore((state) => state.players);
  const roundScores = useSneakyGooseStore((state) => state.roundScores);
  const totalRounds = useSneakyGooseStore((state) => state.rounds);
  const halvedScores = useSneakyGooseStore((state) => state.halvedScores);
  const recordScoresForRound = useSneakyGooseStore(
    (state) => state.recordScoresForRound
  );
  const gameEnded = useSneakyGooseStore((state) => state.gameEnded);
  const reset = useSneakyGooseStore((state) => state.reset);

  const calculateSum = (index: number) => {
    if (roundScores.length > 0 && index < roundScores[0].length) {
      return roundScores.reduce((acc, currentRound) => {
        return acc + (currentRound[index] ?? 0);
      }, 0);
    } else {
      return 0;
    }
  };

  const renderFinalStandings = () => {
    const standings = players
      .map((player, index) => ({
        name: player,
        score: calculateSum(index),
        originalIndex: index,
      }))
      .sort((a, b) => a.score - b.score);

    let previousScore: number | null = null;
    let rank = 0;
    let rankOffset = 0;

    return standings.map((player, index) => {
      if (player.score !== previousScore) {
        rank = index + 1 - rankOffset;
        previousScore = player.score;
      } else {
        rankOffset++;
      }

      const avatar = rankedBirds[rank - 1];

      return (
        <React.Fragment key={player.originalIndex}>
          <Flex justify="center" mt="4">
            <Text size="7">
              <strong>{`${rank}${["st", "nd", "rd", "th"][rank > 3 ? 3 : rank - 1]}`}</strong>
              {avatar} {player.name} : {player.score}
            </Text>
          </Flex>
        </React.Fragment>
      );
    });
  };

  return (
    <>
    <Flex width={"100%"} justify="center">


    </Flex>
      <Flex
        className={styles.GoosePage}
        direction={"column"}
        p="4"
      >
        {!gameStarted ? (
          <>
            <Flex justify="between" align={"center"}>
              <img src={goose} width="50px" />
              <Heading>Score Tracker</Heading>
              <Button size="3" onClick={() => handleExit()}>
                Exit
              </Button>
            </Flex>
            <Flex direction="column">
              <Text mt="9">
                Number of Rounds: <strong>{rounds}</strong>
              </Text>
              <Slider.Root
                className={styles.SliderRoot}
                defaultValue={[5]}
                max={10}
                step={1}
                onValueChange={(value) => {
                  setRounds(value[0]);
                }}
              >
                <Slider.Track className={styles.SliderTrack}>
                  <Slider.Range className={styles.SliderRange} />
                </Slider.Track>
                <Slider.Thumb
                  className={styles.SliderThumb}
                  aria-label="Volume"
                />
              </Slider.Root>

                <Flex direction={"column"} mt="5">

              {playerNames.map((player, index) => (
                <TextField.Root
                  key={index}
                  mt={index === 0 ? "6" : "3"}
                  size="3"
                  required={player.required || false}
                  name="name"
                  onChange={(e) => player.setter(e.target.value)}
                  value={player.value}
                  type="text"
                  className={`${styles.input}`}
                  placeholder={player.placeholder}
                />
              ))}
                </Flex>
            </Flex>

            <Button mt="9" size={"3"} onClick={handleStart}>
              Start Game
            </Button>
          </>
        ) : (
          <>
            {!gameEnded ? (
              <>
                <Flex direction={"column"}>
                  <Flex justify={"between"}>
                    <Heading as="h1" mb="6" size="8" align="center">
                      {totalRounds === currentRound
                        ? "Final Round"
                        : `Round ${currentRound}`}
                    </Heading>

                    <Button size="3" onClick={handleReset}>
                      End Game
                    </Button>
                  </Flex>
                  <Flex direction="row" justify="between" gap="1">
                    <Flex direction="column" width="100%">
                      <Flex align="center">
                        <Flex justify="start" width="70%">
                          <Heading as="h2" size="6">
                            Player Scores
                          </Heading>
                        </Flex>
                        <Flex justify="center" width="30%">
                          <Heading align="center" size="6">
                            Honk?
                          </Heading>
                        </Flex>
                      </Flex>

                      {players.map((player, index) => (
                        <Flex key={index} mt="2" direction="row" align="center">
                          <Flex direction="column" width="70%">
                            <Text size="3" mb="2">
                              <strong>{player}</strong>
                            </Text>
                            <TextField.Root
                              size="3"
                              name={`score${index + 1}`}
                              onChange={(e) => handleScoreChange(e, index)}
                              value={
                                playerNames[index].score === null
                                  ? ""
                                  : (playerNames[index].score as any)
                              }
                              type="number"
                              className={`${styles.input}`}
                              placeholder="Enter Round Score..."
                            />
                          </Flex>

                          <Flex direction="column" align="center" width="30%">
                            <Checkbox
                              mt="6"
                              size="3"
                              color="orange"
                              checked={honkerIndex === index}
                              onCheckedChange={() => setHonkerIndex(index)}
                            />
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>

                  <Text mt="6" size="6">
                    <strong>Current Scores:</strong>
                  </Text>
                  {players.map((player, index) => (
                    <React.Fragment key={index}>
                      <Text size="5" mt="2">
                        <strong>{player}</strong>: {calculateSum(index)}
                        {halvedScores.includes(index) && (
                          <Text ml="2" color="red">
                            Score Halved!
                          </Text>
                        )}
                      </Text>
                    </React.Fragment>
                  ))}

                  <Button mt="5" size={"3"} onClick={handleSubmitScores}>
                    Submit Round {currentRound} Scores
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <Flex direction={"column"}>
                  <Heading align="center" size="7" as="h1">
                    HONK HONK CONGRATULATIONS!
                  </Heading>

                  <Flex direction="column">
                    <Text align="center" size="7" mt="6">
                      <strong>Final Standings:</strong>
                    </Text>
                    {renderFinalStandings()}
                  </Flex>
                </Flex>
                <Flex justify={"between"}>
                  <Button size={"3"} onClick={reset}>
                    Play Again
                  </Button>
                  <Link to={routesConfig["SNEAKY_GOOSE"].path}>
                    <Button size="3" onClick={reset}>
                      Exit
                    </Button>
                  </Link>
                </Flex>
              </>
            )}
          </>
        )}
      </Flex>
    </>
  );
};

export default SneakyGooseScoreTracker;
