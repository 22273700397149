export const s3PublicBaseURL =
  "https://worgangamesstorage111521-dev.s3.eu-west-1.amazonaws.com/public";

export const contactCategories = [
  { id: "1", label: "Returns / Exchange" },
  { id: "2", label: "Tracking" },
  { id: "3", label: "Shipping" },
  { id: "4", label: "Order Mishaps" },
  { id: "5", label: "Rule Clarification" },
  { id: "6", label: "Wholesale" },
  { id: "7", label: "Other" },
];

export const productsData = {
  PLUNDER: {
    comingSoon: true,
    id: "price_1PFw3nI31a28WVPT0xYHL3Yp",
    title: "Plunder",
    price: 18.99,
    oldPrice: 22.99,
    image: "/shared/images/plunderbox.webp",
    path: "PLUNDER",
    productImages: [
      "https://picsum.photos/100",
      "https://picsum.photos/200",
      "https://picsum.photos/300",
      "https://picsum.photos/400",
    ],
    description: [
      `Set sail for adventure with Plunder! The swashbuckling card game that pits you against yer fellow buccaneers to see who’s the most cunning pirate of ‘em all.`,

      `To claim victory, gather three full fleets of ships before yer rivals do. Keep yer treasures safe and yer plans secretive, as the seas are filled with foes eager to snatch yer hard-earned booty.`,
    ],
    detailsItems: [ "Number of Players: 2-5 Players",
    "Play time: 15 - 30 minutes",
    "Ages: 8+"],
    included: ["28 Ship Cards", "8 Captain Cards", "13 Plunder Cards", "18 Treasure Cards", "33 Action Cards"]
  },
  JINX: {
    comingSoon: true,
    amazonLink: "https://www.amazon.co.uk/Worgan-Games-JINX-Fast-Paced-Stocking/dp/B0DGQL47S8/",
    id: "price_1PFw41I31a28WVPT9FPKxYD8",
    title: "Jinx",
    price: 14.99,
    oldPrice: 16.99,
    image: "/shared/images/plunderbox.webp",
    path: "JINX",
    productImages: [
      "https://picsum.photos/100",
      "https://picsum.photos/200",
      "https://picsum.photos/300",
      "https://picsum.photos/400",
    ],
    description: [
      ` NO TURNS! Everyone plays at the same time, competing to shout the next number in the count, and slam their card onto their play pile.`,

      `If you shout the same number as someone else at the same time, that's JINX! Pick up that number of cards. The count does not wait for you to finish picking up and your opponents will take advantage of this!`,

      `First player to get rid of all of their cards WINS!`,
    ],
    detailsItems: [ "Number of Players: 2-5 Players",
    "Play time: 15 - 30 minutes",
    "Ages: 8+"],
    included: ["28 Ship Cards", "8 Captain Cards", "13 Plunder Cards", "18 Treasure Cards", "33 Action Cards"]

  },
  SNEAKY_GOOSE: {
    comingSoon: true,
    amazonLink: "https://www.amazon.co.uk/Worgan-Games-Sneaky-Goose-Entertaining/dp/B0DH8G8JGD/",
    id: "price_1PFw41I31b28WVPT9FPKxYD8",
    title: "Sneaky Goose",
    price: 11.99,
    oldPrice: 14.99,
    image: "/shared/images/plunderbox.webp",
    path: "SNEAKY_GOOSE",
    productImages: [
      "https://picsum.photos/100",
      "https://picsum.photos/200",
      "https://picsum.photos/300",
      "https://picsum.photos/400",
    ],
    description: ["First paragraph of the description goes here blah blah blah", "Second paragraph of the description goes here blah blah blah", "Third paragraph of the description goes here blah blah blah"],
    detailsItems: [ "Number of Players: 2-5 Players",
    "Play time: 15 - 30 minutes",
    "Ages: 8+"],
    included: ["28 Ship Cards", "8 Captain Cards", "13 Plunder Cards", "18 Treasure Cards", "33 Action Cards"]
  },
};
