import { BoxIcon, Cross1Icon } from "@radix-ui/react-icons";
import { Box, Button, Flex, Heading, Link, Spinner, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCartStore } from "../../../state";
import styles from "./cart.module.scss";
import CartItem from "./CartItem";

const CartDrawer = () => {
  const location = useLocation();

  const items = useCartStore((state) => state.items);
  const totalCost = useCartStore((state) => state.totalCost);
  const getCheckoutSession = useCartStore((state) => state.getCheckoutSession);
  const cartDrawerOpen = useCartStore((state) => state.cartDrawerOpen);
  const setCartDrawerOpen = useCartStore((state) => state.setCartDrawerOpen);
  const checkoutLoading = useCartStore((state) => state.checkoutLoading);

  const [totalItems, setTotalItems] = useState(0);

  const toggleDrawer = () => {
    setCartDrawerOpen(!cartDrawerOpen);
  };

  useEffect(() => {
    setCartDrawerOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    setTotalItems(items.reduce((sum, product) => sum + product.quantity, 0));
  }, [items]);

  return (
    <>
      <Flex>
        {cartDrawerOpen && (
          <Box className={styles.Backdrop} onClick={toggleDrawer}></Box>
        )}

        <Flex
          className={`${styles.Drawer} ${cartDrawerOpen ? styles.open : ""}`}
          direction="column"
          px="6"
        >
          <Box className={styles.DrawerContent}>
            <Flex justify={"between"}>
              <Heading>CART</Heading>
              <button className={styles.DrawerToggle} onClick={toggleDrawer}>
                <Flex>
                  <Cross1Icon />
                </Flex>
              </button>
            </Flex>
            {totalItems === 0 ? (
              <>There are no items currently in your cart.</>
            ) : (
              <Flex direction={"column"} justify="center">
                <Heading as="h2">Items in your cart: </Heading>
                {items.map((product, index) => (
                  <Flex key={index} direction={"column"}>
                    <CartItem product={product} />
                  </Flex>
                ))}
                <Box my="4">
                  <hr />
                </Box>

                <Heading my="4">Subtotal: £{totalCost.toFixed(2)}</Heading>

                <Button
                  size="4"
                  onClick={() => {
                    getCheckoutSession();
                  }}
                >
                  {checkoutLoading ? <><Spinner /></> : "Checkout"}
                </Button>
                <Text size={'2'}>Free Delivery | Delivery within 1-2 days | Full refund on returns within 30 days</Text>


                <Flex justify="center">
                  <Link
                    className={styles.continueShoppingLink}
                    mt="4"
                    size="4"
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    Continue Shopping
                  </Link>
                </Flex>
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default CartDrawer;
