import { useState, useEffect } from "react";
import styles from "./BreathingAnimation.module.scss";
import inhale from "/shared/images/inhale.webp";
import exhale from "/shared/images/Exhale.webp";

import { Flex } from "@radix-ui/themes";

const BreathingAnimation = () => {
  const [isInhale, setIsInhale] = useState(true);

  useEffect(() => {
    const inhaleDuration = 50; 
    const exhaleDuration = 2000; 

    const interval = setInterval(() => {
      setIsInhale((prev) => !prev);
    }, inhaleDuration + exhaleDuration);

    return () => clearInterval(interval);
  }, []);

  return (
    <Flex  justify="center" p={{initial: '4', md: '6'}}>
      {isInhale ? (
        <img src={inhale} alt="Inhale" className={styles.inhale} />
      ) : (
        <img src={exhale} alt="Exhale" className={styles.exhale} />
      )}
    </Flex>
  );
};

export default BreathingAnimation;