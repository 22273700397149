import { Box, Theme } from "@radix-ui/themes";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes } from "react-router-dom";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import OptOut from "./pages/FreeStuff/OptOut/OptOut";
import Home from "./pages/Home/Home";
import Jinx from "./pages/Jinx/Jinx";
import Noise from "./pages/Noise/Noise";
import Privacy from "./pages/Privacy/Privacy";
import Product from "./pages/Product/Product";
import PunchyKicky from "./pages/PunchyKicky/PunchyKicky";
import PunchyKickyScoreTracker from "./pages/PunchyKicky/ScoreTracker";
import Shop from "./pages/Shop/Shop";
import SneakyGooseScoreTracker from "./pages/SneakyGoose/ScoreTracker";
import SneakyGoose from "./pages/SneakyGoose/SneakyGoose";
import SuccessfulPayment from "./pages/SuccessfulPayment/SuccessfulPayment";
import Terms from "./pages/Terms/Terms";
import Brass from "./pages/TopBrass/TopBrass";
import Wholesale from "./pages/Wholesale/Wholesale";
import Footer from "./shared/components/footer/Footer";
import Navbar from "./shared/components/navbar/Navbar";
import ToasterMessage from "./shared/components/toast/toast";
import { useAnalyticsPageView } from "./shared/hooks/useAnalyticsPageView";
import { useCartStore } from "./state";

export const routesConfig = {
  HOME: {
    path: "/",
    component: Home,
    title: "Worgan Games",
    description: "DESCRIPTION HERE FOR HOME | Worgan Games.",
  },
  ABOUT: {
    path: "/about",
    component: About,
    title: "About Us | Worgan Games",
    description: "DESCRIPTION HERE FOR HOME | Worgan Games.",
  },
  CONTACT: {
    path: "/contact",
    component: Contact,
    title: "Shop | Worgan Games",
    description: "Help and Contact | Worgan Games.",
  },
  SHOP: {
    path: "/shop",
    component: Shop,
    title: "Shop | Worgan Games",
    description: "DESCRIPTION HERE FOR HOME | Worgan Games.",
  },
  WHOLESALE: {
    path: "/wholesale",
    component: Wholesale,
    title: "Wholesale | Worgan Games",
    description: "SELL OUR GAMES | Worgan Games.",
  },
  JINX: {
    path: "/jinx",
    component: Jinx,
    title: "Jinx | Worgan Games",
    description: "DESCRIPTION HERE FOR JINX | Worgan Games.",
  },
  TOP_BRASS: {
    path: "/top-brass",
    component: Brass,
    title: "Top Brass | Worgan Games",
    description: "DESCRIPTION HERE FOR TOP BRASS | Worgan Games.",
  },
  GUESS_THAT_NOISE: {
    path: "/guess-that-noise",
    component: Noise,
    title: "Guess that Noise | Worgan Games",
    description: "DESCRIPTION HERE FOR GUESS THAT NOISE | Worgan Games.",
  },
  SNEAKY_GOOSE_SCORE_TRACKER: {
    path: "/sneaky-goose/score-tracker",
    component: SneakyGooseScoreTracker,
    title: "Sneaky Goose Score Tracker | Worgan Games",
    description:
      "DESCRIPTION HERE FOR Sneaky Goose Score Tracker | Worgan Games.",
    noNav: true
    },
    
  SNEAKY_GOOSE: {
    path: "/sneaky-goose",
    component: SneakyGoose,
    title: "Sneaky Goose | Worgan Games",
    description: "DESCRIPTION HERE FOR Sneaky Goose | Worgan Games.",
  },
  PUNCH_KICK: {
    path: "/punchy-kicky",
    component: PunchyKicky,
    title: "Punchy Kicky | Worgan Games",
    description: "DESCRIPTION HERE FOR Punchy Kicky | Worgan Games.",
  },
  PUNCHY_KICKY_SCORE_TRACKER: {
    path: "/punchy-kicky/score-tracker",
    component: PunchyKickyScoreTracker,
    title: "Punchy Kicky Score Tracker | Worgan Games",
    description:
      "DESCRIPTION HERE FOR Punchy Kicky Score Tracker | Worgan Games.",
  },
  PRIVACY: {
    path: "/privacy",
    component: Privacy,
    title: "Privacy policy | Worgan Games",
    description: "DESCRIPTION HERE FOR PRIVACY | Worgan Games.",
  },
  TERMS: {
    path: "/terms",
    component: Terms,
    title: "Terms of Service | Worgan Games",
    description: "DESCRIPTION HERE FOR TERMS | Worgan Games.",
  },
  PRODUCT: {
    path: "/shop/:productName",
    component: Product,
    title: "Shop | Worgan Games",
    description: "DESCRIPTION HERE FOR PRODUCT | Worgan Games.",
  },
  CHECKOUT_SESSION_SUCCESS: {
    path: "/successful-payment",
    component: SuccessfulPayment,
    title: "Payment Success | Worgan Games",
    description: "Your payment was successful. Thank you for your order! | Worgan Games.",
  },
  FREE_STUFF_OPT_OUT: {
    path: "/free-stuff/opt-out",
    component: OptOut,
    title: "Opt Out of Free Games | Worgan Games",
    description: "For some reason you want to opt out of free games | Worgan Games.",
  },
};

const gtag = import.meta.env.VITE_GTAG!;
const allowIndexing = import.meta.env.VITE_ALLOW_INDEXING === "true";
const filesBucket = import.meta.env.VITE_FILES_BUCKET;
const env = import.meta.env.VITE_ENV!;

ReactGA.initialize(gtag);

const App = () => {
  useAnalyticsPageView();

  const setProductsData = useCartStore((state) => state.setProductsData);

  useEffect(() => {
    const url = `https://${filesBucket}.s3.eu-west-1.amazonaws.com/${env}-productsData.json.gz`;
    fetch(url)
      .then((response) => response.json())
    .then((jsonData) => {
        setProductsData(jsonData);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  return (
    <Routes>
      {Object.values(routesConfig).map(
        ({ path, component: Component, title, description, noNav }: any, index) => (
          <Route
            key={index}
            path={path}
            element={
              <>
                {title && description ? (
                  <>
                    <Helmet>
                      <title>{title}</title>
                      <meta name="description" content={description} />
                      {!allowIndexing ? (
                        <meta name="robots" content="noindex, nofollow" />
                      ) : (
                        <meta name="robots" content="index,follow" />
                      )}
                    </Helmet>
                  </>
                ) : (
                  <></>
                )}

                <Box className={`${path.replace("/", "")}`}>
                  <Theme
                    appearance={"light"}
                    accentColor={"orange"}
                    radius={"none"}
                  >
                    {!noNav ? <Navbar /> : <></>}
                    <Box className={`main-body`}>
                      <Component />
                      <ToasterMessage />
                    </Box>
                    <Footer />
                  </Theme>
                </Box>
              </>
            }
          />
        )
      )}
      <Route path="*" element={<Navigate to={routesConfig["HOME"].path} />} />
    </Routes>
  );
};

export default App;
