import {
  ArrowRightIcon, EnvelopeClosedIcon
} from "@radix-ui/react-icons";
import { IconButton, Spinner, Text, TextField } from "@radix-ui/themes";
import { useState } from "react";
import * as Yup from "yup";
import { useNewsletterStore, useToastStore } from "../../../state";
import styles from "./submit-email-field.module.scss";

const emailValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required")
    .max(50, "Email cannot be longer than 50 characters"),
});

const SubmitEmailField = () => {
  const { submitEmail } = useNewsletterStore();
  const { triggerToast } = useToastStore();
  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const validateEmail = async () => {
    try {
      await emailValidationSchema.validate({ email });
      setError(undefined);
      return true;
    } catch (validationError) {
      setError((validationError as Yup.ValidationError).message);
      return false;
    }
  };

  const handleSubmit = async () => {
    const isValid = await validateEmail();
    if (!isValid) return; 

    setSending(true);
    try {
      await submitEmail(email);
      triggerToast(
        "Email Successfully Sent",
        "We will be in contact with our latest release!"
      );
      setEmail("");
    } catch (error) {
      console.error("Failed to submit email:", error);
      triggerToast("Something went wrong", "Please refresh and try again.");
    } finally {
      setSending(false);
    }
  };

  return (
    <div>
      <TextField.Root
        placeholder="Enter your email..."
        value={email}
        size={"3"}
        onChange={(e) => setEmail(e.target.value)}
        onBlur={validateEmail}
        disabled={sending}
        className={error ? styles.errorInput : ""}
      >
        <TextField.Slot>
          <EnvelopeClosedIcon height="16" width="16" />
        </TextField.Slot>

        <IconButton
          size="2"
          className={styles.FieldButton}
          onClick={handleSubmit}
        >
          {sending ? <Spinner /> : <ArrowRightIcon height="20" width="20" />}
        </IconButton>
      </TextField.Root>

      {error && (
        <Text className={styles.errorMessage}>*{error}</Text>
      )}
    </div>
  );
};

export default SubmitEmailField;
