import { Flex, Heading, Text } from "@radix-ui/themes";
import styles from "./Header.module.scss";

interface HeaderProps {
  background?: string;
  heading?: string;
  subtext?: string;
  blur?: boolean;
  thin?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  background,
  heading,
  subtext,
}) => {
  return (
    <Flex>
      {!!background ? (
          <img src={background} className={styles.HeaderImage}/>
      ) : (
        <></>
      )}
      <Flex   
        direction="column"
        align="center"
        justify="center"
        gap="5"
        p="8" 
        width={'100%'}
        className={styles.HeaderContainer}
        >

        {!!heading ? (
          <Flex className={styles.headingContainer}>
            <Heading as="h1" size="9" align={"center"}>
              {heading}
            </Heading>
          </Flex>
        ) : (
          <></>
        )}

        {!!subtext ? (
          <Flex
            align="center"
            justify="center"
            width={{ initial: "80%", md: "40%" }}
            className={styles.subTextContainer}
          >
            <Text align="center" size="6">{subtext}</Text>
          </Flex>
        ) : (
          <></>
        )}
      </Flex>

    </Flex>
  );
};

export default Header;
