import React, { useState, useEffect } from "react";
import {
  TextField,
  TextArea,
  Grid,
  Box,
  Flex,
  Text,
  Card,
  Button,
  Heading,
} from "@radix-ui/themes";
import * as Label from "@radix-ui/react-label";
import { useContactStore } from "../../../state";
import * as Yup from "yup";
import styles from "./contact-us-wholesale.module.scss";
import RedAsterisk from "../red-asterisk/RedAsterisk";
import { useToastStore } from "../../../state";
import classNames from "classnames";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .max(30, "Name cannot be longer than 30 characters"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required")
    .max(50, "Email cannot be longer than 50 characters"),
  companyName: Yup.string()
    .required("Company name is required")
    .max(30, "Company name cannot be longer than 30 characters"),
  message: Yup.string()
    .required("Message is required")
    .max(500, "Message cannot be longer than 500 characters"),
});

type Field = "name" | "email" | "companyName" | "message";

const ContactWholesale: React.FC = () => {
  const submitContactForm = useContactStore((state) => state.submitContactForm);
  const contacted = useContactStore((state) => state.contacted);
  const setContacted = useContactStore((state) => state.setContacted);

  const name = useContactStore((state) => state.name);
  const email = useContactStore((state) => state.email);
  const companyName = useContactStore((state) => state.companyName);
  const message = useContactStore((state) => state.message);
  const setName = useContactStore((state) => state.setName);
  const setEmail = useContactStore((state) => state.setEmail);
  const setCompanyName = useContactStore((state) => state.setCompanyName);
  const setMessage = useContactStore((state) => state.setMessage);
  const { triggerToast } = useToastStore();

  const [errors, setErrors] = useState<Record<Field, string | undefined>>({
    name: undefined,
    email: undefined,
    companyName: undefined,
    message: undefined,
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const validateField = async (field: Field, value: string) => {
    try {
      await validationSchema.validateAt(field, { [field]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
    } catch (err) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: (err as Yup.ValidationError).message,
      }));
    }
  };

  const handleContactSubmit = async () => {
    try {
      const formValues = {
        subject: "Wholesale",
        name,
        email,
        companyName,
        message,
      };
      await validationSchema.validate(formValues, { abortEarly: false });
      await submitContactForm(formValues, true);
      triggerToast("Message Sent", "Thank you for contacting us, we will be in touch shortly!");
    } catch (err) {
      const validationErrors: Record<Field, string | undefined> = {
        name: undefined,
        email: undefined,
        companyName: undefined,
        message: undefined,
      };
      (err as Yup.ValidationError).inner.forEach((error) => {
        validationErrors[error.path as Field] = error.message;
      });
      setErrors(validationErrors);
      triggerToast("Submission Failed", "Please check the form and try again.");
    }
  };

  useEffect(() => {
    const checkFormValidity = async () => {
      try {
        await validationSchema.validateAt("name", { name });
        await validationSchema.validateAt("email", { email });
        await validationSchema.validateAt("companyName", { companyName });
        await validationSchema.validateAt("message", { message });
        setIsSubmitDisabled(false);
      } catch {
        setIsSubmitDisabled(true);
      }
    };

    checkFormValidity();
  }, [name, email, companyName, message]);

  useEffect(() => {
    return () => {
      setContacted(false);
    };
  }, [setContacted]);

  return (
    <Card className={classNames("testimonial-card", styles.formCard )} mb="9">
      <Flex direction="column" p="7" flexGrow="1">
        <Heading align="center"as="h2" size={"8"}>
          GET IN TOUCH
        </Heading>
        <Text align="center"mb="6">We aim to reply to all messages within 24 hours.</Text>
        <Grid columns={{ initial: "1", md: "2" }} gap="3">
          <Flex direction="column">
            <Flex justify="between" align="center">
              <Label.Root className={styles.heading}>
                Name
                <RedAsterisk />
              </Label.Root>
              {errors.name && (
                <Text className={styles.errorMessage}>{errors.name}</Text>
              )}
            </Flex>

            <TextField.Root
              size="3"
              name="name"
              onBlur={(e) => validateField("name", e.target.value)}
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              className={`${styles.input} ${errors.name ? styles.error : ""}`}
            />
          </Flex>

          <Flex direction="column">
            <Flex justify="between" align="center">
              <Label.Root className={styles.heading}>
                Email
                <RedAsterisk />
              </Label.Root>
              {errors.email && (
                <Text className={styles.errorMessage}>{errors.email}</Text>
              )}
            </Flex>

            <TextField.Root
              size="3"
              name="email"
              onBlur={(e) => validateField("email", e.target.value)}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="text"
              className={`${styles.input} ${errors.email ? styles.error : ""}`}
            />
          </Flex>

          <Flex direction="column">
            <Flex justify="between" align="center">
              <Label.Root className={styles.heading}>
                Company Name
                <RedAsterisk />
              </Label.Root>
              {errors.companyName && (
                <Text className={styles.errorMessage}>
                  {errors.companyName}
                </Text>
              )}
            </Flex>

            <TextField.Root
              size="3"
              name="companyName"
              onBlur={(e) => validateField("companyName", e.target.value)}
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
              type="text"
              className={`${styles.input} ${errors.companyName ? styles.error : ""}`}
            />
          </Flex>
        </Grid>

        <Flex mt="3" direction="column">
          <Flex justify="between" align="center">
            <Label.Root className={styles.heading}>
              Message
              <RedAsterisk />
            </Label.Root>
            {errors.message && (
              <Text className={styles.errorMessage}>{errors.message}</Text>
            )}
          </Flex>
        </Flex>
        <TextArea
          name="message"
          rows={5}
          value={message}
          onBlur={(e) => validateField("message", e.target.value)}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Write your message here..."
          className={`${styles.textarea} ${errors.message ? styles.error : ""}`}
        />
        <Button
          mt="6"
          size="3"
          className="primaryButton"
          onClick={handleContactSubmit}
          disabled={isSubmitDisabled}
        >
          SUBMIT
        </Button>
      </Flex>
    </Card>
  );
};

export default ContactWholesale;
