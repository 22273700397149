import { Box, Flex } from "@radix-ui/themes";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../App";
import styles from "./carousel.module.scss";

interface CarouselCardProps {
  background: string;
  comingSoon?: boolean;
  link: string;
}

const CarouselCard: React.FC<{ card: CarouselCardProps }> = ({ card }) => {
  const { background, comingSoon, link } = card;
  const linkPath = routesConfig[link as keyof typeof routesConfig]?.path || "/";
  const navigate = useNavigate();

  return (
    <Flex
      height={{initial:"250px", sm:"500px"}}
      className={styles.carouselCard}
      style={{ cursor: 'pointer' }}
      onClick={() => navigate(linkPath)}
    >
      <Flex
        className={styles.backgroundImage}
        style={{ backgroundImage: `url(${background})` }}
      />
      {comingSoon && (
        <Flex align="center" justify="center"className={styles.comingSoonBanner}>
          <Box>Coming Soon</Box>
        </Flex>
      )}
    </Flex>
  );
};

export default CarouselCard;