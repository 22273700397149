import { StarFilledIcon } from "@radix-ui/react-icons";
import { Flex, Text } from "@radix-ui/themes";

interface TestimonialWallProps {
  reviews: any;
}

const TestimonialWall: React.FC<TestimonialWallProps> = ({ reviews }) => {
  return (
    <section className=" mx-auto w-full dark:bg-gray-800 dark:text-white mb-6">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 w-full">
        {reviews?.map((review: any) => (
          <div className="border p-7 rounded-xl bg-white dark:bg-gray-700 drop-shadow-md border-neutral-200/50 col-span-2 flex flex-col gap-y-10 justify-between testimonial-card">
            <div className="flex flex-col gap-y-3.5">
              <p className="font-bold text-xl">{review?.title}</p>
              <Flex mb="2" align={"center"}>
                <StarFilledIcon color="gold" className="w-3 h-3" />
                <StarFilledIcon color="gold" className="w-3 h-3" />
                <StarFilledIcon color="gold" className="w-3 h-3" />
                <StarFilledIcon color="gold" className="w-3 h-3" />
                <StarFilledIcon color="gold" className="w-3 h-3" />
                <Text ml="1">{review?.rating} stars out of 5</Text>
              </Flex>
              <p className="font-medium text-slate-700/90 dark:text-gray-300">
                {review?.text}
              </p>
              
            </div>
            <div className="flex flex-col">
              <p className="text-sm font-semibold">{review?.reviewer}</p>
              <p className="text-sm font-medium text-slate-700/70 dark:text-gray-400">
                {review?.when}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialWall;
