import { get, post } from "aws-amplify/api";

export const getRequest: any = async (path: string) => {
  try {
    const getOperation = get({
      apiName: "worganGames",
      path,
      options: {},
    });
    const { body } = await getOperation.response;
    const response = await body.json();
    return response;
  } catch (error) {
    console.log('POST call failed: ', error);
  }
}

export const postRequest: any = async (path: string, data: any) => {
  try {
    const postOperation = post({
      apiName: "worganGames",
      path,
      options: {body: data},
    });
    const { body } = await postOperation.response;
    const response = await body.json();
    return response;
  } catch (error) {
    console.log('POST call failed: ', error);
  }
}
