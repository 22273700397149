import { Flex, Heading } from "@radix-ui/themes";
import CustomCarousel from "../../shared/components/carousel/carousel";
import carouselData from "../../shared/constants/homeCarousel.json";
import HowToPlayJinx from "../../Jinx/Components/HowToPlayJinx/HowToPlayJinx";
import Header from "../../shared/components/header/header";
import ExplainerSection from "../../shared/components/explainer-section/ExplainerSection";
import BuyNow from "../../shared/components/buy-now-section/BuyNow";
import EmailSubmission from "../../shared/components/email-submission/email-submission";
import MarketingSection from "../../shared/components/marketing-section/MarketingSection";
import jinxMarketing from "../../shared/constants/jinxMarketingData.json";

const Jinx = () => {

  return (
    <>
      <Flex className="MainContainer" direction={"column"}>
      <ExplainerSection
        title="Jinx"
        description="The Chaotic Card Game That's Shockingly Fun"
        listItems={["3-5 Players", "5 Minute Playtime", "Ages 7+"]}
        image="/shared/images/JinxBox.webp"
        productName={"JINX"}
      />

      <MarketingSection data={jinxMarketing}/>

      <HowToPlayJinx />

      <EmailSubmission />
      <CustomCarousel cards={carouselData} />
      </Flex>
    </>
  );
};

export default Jinx;
