import { Box, Card, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import React from "react";
import styles from "./Marketing.module.scss";

interface CardData {
  title: string;
  description: string;
  image: string;
}

interface MarketingSectionProps {
  
  data: CardData[];
}

const MarketingSection: React.FC<MarketingSectionProps> = ({
  
  data,
}) => {

  return (
    <Flex align="center" py="6"  direction="column" className={styles.marketingContainer}> 
    
      <Grid
        justify="between"
        gap="5"
        px={{ initial: "4", md: "9" }}
        columns={{ initial: "1", md: "3" }}
      >
        {data.map((item, index) => (
          //<Card key={index}>
            <Flex
              direction="column"
              justify="center"
              align="center"
              p="7"
              gap="5"
              className="wholesaleCard"
            >
              <Flex justify="center" height={{ initial: "200px", md: "300px" }} className={styles.imageContainer}>
              <img  src={item.image} alt={item.title} />
              </Flex>
              <Flex direction="column" className={styles.marketingText} >
              <Heading as="h3" size="8" align="center">
                {item.title}
              </Heading>
              <Text size="5" align="center">
                {item.description}
              </Text>
              </Flex>
            </Flex>
          //</Card>
        ))}
      </Grid>
     
    </Flex>
  );
};

export default MarketingSection;
