
import { useToastStore } from "../../../state";
import styles from "./toast.module.scss";
import * as Toast from "@radix-ui/react-toast";




const ToasterMessage = () => {

  const {open, setOpen, title, subtext  } = useToastStore();

  return (
    <Toast.Provider >
      <Toast.Root className={styles.Root} open={open} onOpenChange={setOpen} duration={4000}>
        <Toast.Title className={styles.Title}>{title}</Toast.Title>
        <Toast.Description className={styles.Description}>{subtext}</Toast.Description>
        <Toast.Close />
      </Toast.Root>
      <Toast.Viewport className={styles.Viewport}/>
    </Toast.Provider>
  );
};

export default ToasterMessage;