import {

    Flex,

} from "@radix-ui/themes";
import Header from "../../shared/components/header/header";
import WhyChooseUs from "../../shared/components/why-worgan-games/WhyWorganGame";
import ContactWholesale from "../../shared/components/contact-us-wholesale/contactWholesale";

const Wholesale = () => {
  return (
    <>
      <Header
        heading={"PARTNER WITH WORGAN GAMES"}
        subtext={
          "Want to add our exciting games to your lineup? We're ready to make it happen."
        }
        blur={false}
        thin={true}
      />

      <Flex
        gap="9"
        align="center"
        direction="column"
        justify="start"
        px="5"
      >
        <WhyChooseUs/>
     
        <ContactWholesale />
        
      </Flex>
    </>
  );
};

export default Wholesale;
