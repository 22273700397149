import {
  Cross1Icon, HamburgerMenuIcon
} from "@radix-ui/react-icons";
import { Box, Flex } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routesConfig } from "../../../App";
import styles from "./Navbar.module.scss";
import logo from "/shared/images/logo.webp";

const MobileNav = () => {

  const navigate = useNavigate();
  
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <>
      <Flex display={{ initial: "flex", md: "none" }} justify="center">

        <button className={styles.DrawerToggle} onClick={toggleDrawer}>
          {isOpen ? <Cross1Icon color="white"/> : <HamburgerMenuIcon color="white"/>}
        </button>
      </Flex>
      {isOpen && <Box className={styles.Backdrop} onClick={toggleDrawer}></Box>}

      <Box className={`${styles.Drawer} ${isOpen ? styles.open : ""}`}>
        <Box className={styles.DrawerContent}>
          <Link to={routesConfig["HOME"].path}>
            <Flex className={styles.LogoBox} mx="6" justify={"center"} mb="6">
              <img src={logo} alt="Worgan Games Logo" width="150px" />
            </Flex>
          </Link>
          <Link
            className={styles.NavigationMenuLink}
            to={routesConfig["SHOP"].path}
          >
            SHOP
          </Link>
          <Link
            className={styles.NavigationMenuLink}
            to={routesConfig["JINX"].path}
          >
            JINX
          </Link>
          <Link
            className={styles.NavigationMenuLink}
            to={routesConfig["SNEAKY_GOOSE"].path}
          >
            SNEAKY GOOSE
          </Link>
          <Link
            className={styles.NavigationMenuLink}
            to={routesConfig["GUESS_THAT_NOISE"].path}
          >
            GUESS THAT NOISE
          </Link>
          <Link
            className={styles.NavigationMenuLink}
            to={routesConfig["TOP_BRASS"].path}
          >
            TOP BRASS
          </Link>
          
          <Link
            className={styles.NavigationMenuLink}
            to={routesConfig["WHOLESALE"].path}
          >
            WHOLESALE
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default MobileNav;
