import {
  CheckCircledIcon
} from "@radix-ui/react-icons";
import {
  Box, Card,
  Flex, Heading, Text
} from "@radix-ui/themes";
import React from "react";
import SubmitEmailField from "../submit-email-field/submit-email-field";
import styles from "./email-submission.module.scss";

const EmailSubmission: React.FC = () => {
  return (
    <Flex justify="center" className={styles.EmailSubmissionContainer} p={{initial: '5', md: '9'}}>
        {/* <Card className={styles.EmailSubmissionCard}> */}

          <Flex direction={"column"} align="center" p={{initial: '4', md: '6'}} >
            <Heading as="h2" align={"center"} size="8">
              Never Miss A New Game Release
            </Heading>
            <Text mt="3" align={"center"}>
              Stay ahead of the curve and be the first to know about the latest
              game releases.
            </Text>
            <Flex justify="center" mt="6" className={styles.FieldContainer}>
              <SubmitEmailField />
            </Flex>

              <Flex mt="6" direction={{initial: 'column', md: 'row'}}>
            
                <Flex align="center" pb={{initial: '4', md: 'none'}}>
                  <CheckCircledIcon className="h-6 w-6" />
                  <Flex >
                    <Text ml="2" mr="4" size={"6"} >
                      Be First To Know
                    </Text>
                  </Flex>
                </Flex>
               
              
                <Flex align="center" pb={{initial: '4', md: 'none'}}>
                  <CheckCircledIcon className="h-6 w-6" />
                  <Flex>
                    <Text ml="2" mr="4" size={"6"}>
                      Exclusive Special Offers
                    </Text>
                  </Flex>
                </Flex>
            
                <Flex align="center" pb={{initial: '4', md: 'none'}}>
                  <CheckCircledIcon className="h-6 w-6" />
                  <Flex>
                    <Text ml="2" size={"6"}>
                      Beta Testing Opportunities
                    </Text>
                  </Flex>
              </Flex>
            </Flex>
          </Flex>
        {/* </Card> */}
    </Flex>
  );
};

export default EmailSubmission;
