import { CaretDownIcon } from "@radix-ui/react-icons";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { Box, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import classNames from "classnames";
import { forwardRef, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routesConfig } from "../../../App";
import Cart from "../cart/Cart";
import CartDrawer from "../cart/CartDrawer";
import styles from "./Navbar.module.scss";
import logo from "/shared/images/logo.webp";
import Goose from "/shared/images/SneakyGooseLogo.webp";
import Noise from "/shared/images/NoiseLogo.webp";
import NoiseBG from "/shared/images/SoundBg.webp";
import GooseBG from "/shared/images/SneakyGooseBg2.webp";
import JinxBg from "/shared/images/JinxLogo.webp";
import BrassLogo from "/shared/images/TopBrassLogo.webp";
import BrassBg from "/shared/images/BrassBg4.webp";
import NavbarCard from "../navbar-card/NavBarCard";

const DesktopNav = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const stickyPoint = 100;
    setIsSticky(window.scrollY > stickyPoint);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Flex
        justify={"between"}
        className={`${styles.navbarContainer} ${isSticky ? styles.StickyBlurred : ""}`}
      >
        <Link to={routesConfig["HOME"].path} onClick={handleClick}>
          <Box
            className={styles.LogoBox}
            mx="6"
            display={{ initial: "none", md: "block" }}
          >
            <img src={logo} alt="poop" width="100px" />
          </Box>
        </Link>
        <Flex
          justify={{ initial: "between", md: "center" }}
          px="6"
          align={{ initial: "center", md: "center" }}
          display={{ initial: "none", md: "flex" }}
        >
          <NavigationMenu.Root className={styles.NavigationMenuRoot}>
            <NavigationMenu.List className={styles.NavigationMenuList}>
              <NavigationMenu.Item>
                <NavigationMenu.Link
                  className={classNames(styles.NavigationMenuLink, location.pathname.includes(routesConfig["SHOP"].path) ? styles.selected : "")}
                  href={routesConfig["SHOP"].path}
                >
                  SHOP
                </NavigationMenu.Link>
              </NavigationMenu.Item>

              <NavigationMenu.Item>
                <NavigationMenu.Trigger
                  className={classNames(styles.NavigationMenuTrigger, (location.pathname === (routesConfig["JINX"].path) || location.pathname === (routesConfig["SNEAKY_GOOSE"].path))  ? styles.selected : "")}
                  >
                  OUR GAMES
                  <CaretDownIcon
                    className={classNames(styles.CaretDown, "w-6", "h-6")}
                    aria-hidden
                  />
                </NavigationMenu.Trigger>

                <NavigationMenu.Content
                  className={styles.NavigationMenuContent}
                >
                  <Grid columns="3" p="5" gap="5" className={styles.List}>
                    <Flex justify="center">
                      <a href={routesConfig["JINX"].path}>
                        <NavbarCard imageSrc={JinxBg} background={""} />
                      </a>
                    </Flex>

                    <Flex justify="center">
                      <a href={routesConfig["SNEAKY_GOOSE"].path}>
                        <NavbarCard
                          imageSrc={Goose}
                          background={GooseBG}
                        
                        />
                      </a>
                    </Flex>
                    <Flex justify="center">
                      <a href={routesConfig["GUESS_THAT_NOISE"].path}>
                        <NavbarCard
                          imageSrc={Noise}
                          background={NoiseBG}
                        
                        />
                      </a>
                    </Flex>
                    <Flex justify="center">
                      <a href={routesConfig["TOP_BRASS"].path}>
                        <NavbarCard
                          imageSrc={BrassLogo}
                          background={BrassBg}
                        
                        />
                      </a>
                    </Flex>
                  </Grid>
                </NavigationMenu.Content>
              </NavigationMenu.Item>

              <NavigationMenu.Item>
                <NavigationMenu.Link
                  className={classNames(styles.NavigationMenuLink, location.pathname === (routesConfig["WHOLESALE"].path) ? styles.selected : "")}
                  href={routesConfig["WHOLESALE"].path}
                >
                  WHOLESALE
                </NavigationMenu.Link>
              </NavigationMenu.Item>

              <NavigationMenu.Indicator
                className={styles.NavigationMenuIndicator}
              >
                <div className={styles.Arrow} />
              </NavigationMenu.Indicator>
            </NavigationMenu.List>

            <div className={styles.ViewportPosition}>
              <NavigationMenu.Viewport
                className={styles.NavigationMenuViewport}
              />
            </div>
          </NavigationMenu.Root>
          
        </Flex>
          <img className={styles.MobileNavImage} src={logo} alt="Worgan Games Logo" width="50px"  onClick={() => {
              navigate(routesConfig.HOME.path);
            }}/>
        <Cart />
      </Flex>
      <CartDrawer />
    </>
  );
};

export default DesktopNav;
