import { Container, Heading } from "@radix-ui/themes";

const Terms = () => {
  return (
      <Container py="9">
          <Heading as="h1">Terms of Service</Heading>
          <h2>1. These terms</h2>
        <p>1.1 These are the terms and conditions on which we supply product(s) directly to you and our website more generally. They do not apply to when you buy our product(s) from another retailer such as Amazon or from a shop selling our products. If you have any issues or concerns about products you have bought from third-party retailers you will need to contact the retailer.</p>
        <p>The terms are arranged into the following sections.</p>
        <ul>
            <li>(a) Terms relating to buying products from us</li>
            <li>(c) Terms relating to use of our website</li>
            <li>(d) Other important terms which apply to the purchase of products from us and use of our website.</li>
        </ul>
        <p>1.2 Please read these terms carefully before you submit your order to us. These terms tell you who we are, how we will provide the product(s) to you, how you and we may change or end a contract, what to do if there is a problem and other important information. If you would like to discuss any aspects of these terms, please contact us.</p>

        <h2>2. Who we are and how to contact us</h2>
        <p>2.1 We are BALL TECHNOLOGY LTD T/A WORGAN GAMES (we will refer to ourselves as “Worgan Games“, “we“, “us“, or “our” in these terms). Our company registration number is 13911157 and our registered office is at Pure Offices, Unit 16 Lakeview 600, Lakeside Drive, Centre Park Square, Warrington, England, WA1 1RW. Our registered VAT number is GB404048925.</p>
        <p>2.2 You can contact us by writing to us at info@worgangames.com or BALL TECHNOLOGY LTD T/A WORGAN GAMES, Pure Offices, Unit 16 Lakeview 600, Lakeside Drive, Centre Park Square, Warrington, England, WA1 1RW or by using our customer service form.</p>
        <p>2.3 If we have to contact you we will do so by telephone or by writing to you at the email address or postal address you provided to us in your order.</p>
        <p>2.4 When we use the words “writing” or “written” in these terms, this includes emails.</p>

        <h2>Terms relating to buying products from us</h2>

        <h3>3. General</h3>
        <p>3.1 All orders for products on the Worgan Games website at <a href="https://www.worgangames.com">www.worgangames.com</a> (the “website”) accepted by us are subject to these terms and conditions of sale. No other terms will apply to the supply of products by us.</p>
        <p>3.2 The images of the products on our website are for illustrative purposes only. Your product may vary slightly from those images. The packaging of the product may also vary from that shown in images on our website.</p>
        <p>3.3 We may make minor changes to the products to:</p>
        <ul>
            <li>(a) reflect changes in relevant laws and regulatory requirements; and</li>
            <li>(b) implement minor technical adjustments, improvements or to rectify errors.</li>
        </ul>

        <h3>4. Prices</h3>
        <p>4.1 The price of a product (which includes VAT) will be the price indicated on the order pages when you placed your order. We take all reasonable care to ensure that the price of the product advised to you is correct. However please see clause 4.3 for what happens if we discover an error in the price of the product you order.</p>
        <p>4.2 If the rate of VAT changes between your order date and the date we supply the product, we will adjust the rate of VAT that you pay, unless you have already paid for the product in full before the change in the rate of VAT takes effect.</p>
        <p>4.3 It is always possible that, despite our best efforts, some of the products we sell may be incorrectly priced or that delivery charge will be incorrectly displayed. We will normally check prices before accepting your order so that, where the product’s correct price or delivery charge at your order date is less than our stated price at your order date, we will charge the lower amount. If the product’s correct price or delivery charge at your order date is higher than the price stated to you, we will contact you for your instructions before we accept your order. If we accept and process your order where a pricing error is obvious and unmistakeable and could reasonably have been recognised by you as a mispricing, we will cancel the order, refund you any sums you have paid and require the return of any products provided to you.</p>

        <h3>5. Ordering</h3>
        <p>5.1 The products for sale on the website are for personal non-commercial use only and must not be bought for resale or otherwise for business purposes. Worgan Games will only accept orders from consumers for domestic use on the website. If you are a business customer please contact us via <a href="https://www.worgangames.com">www.worgangames.com</a> (the “website”) to discuss your order or visit <a href="https://www.worgangames.com/sell-our-games">www.worgangames.com/sell-our-games</a> for more information.</p>
        <p>5.2 Our acceptance of your order will take place when we confirm that acceptance to you, at which point a contract will come into existence between you and us.</p>
        <p>5.3 Worgan Games reserves the right to decline to trade with any person. If we are unable to accept your order, we will inform you of this and will not charge you for the product. This might be because the product is out of stock, because of unexpected limits on our resources which we could not reasonably plan for, because we have identified an error in the price or description of the product, or because we are unable to meet a delivery deadline or for any other reason.</p>
        <p>5.4 We will assign an order number to your order and tell you what it is when we accept your order. It will help us if you can tell us the order number whenever you contact us about your order.</p>

        <h3>6. Delivery</h3>
        <p>6.1 The costs of delivery will be as displayed to you on our website.</p>
        <p>6.2 We use a third party courier to deliver your order and we will deliver your order as soon as reasonably possible. During the order process we will let you know when we will aim to deliver the product(s) to you.</p>
        <p>6.3 If our supply of the product(s) is delayed by an event outside our control such as fire, terror, flooding, pandemic, epidemic, or governmental action or direction then we will contact you to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event, but if there is a risk of substantial delay you may contact us to end the contract and receive a refund for any products you have paid for but not received.</p>
        <p>6.4 If no one is available at your address to take delivery and the product(s) cannot be posted through your letterbox, the courier will leave you a note informing you of how to rearrange delivery or collect the product(s) from a local delivery depot.</p>
        <p>6.5 If you do not collect the product(s) from us as arranged or if, after a failed delivery to you, you do not re-arrange delivery or collect them from a local delivery depot we will contact you for further instructions and may charge you for storage costs and any further delivery costs. If, despite our reasonable efforts, we are unable to contact you or re-arrange delivery or collection we may end the contract and clause 13.1 will apply.</p>

        <h3>7. Payment</h3>
        <p>7.1 We accept payment with the payment methods detailed on the website. You must pay for the product(s) before we dispatch them.</p>
        <p>7.2 If you think a payment, or request for payment, is wrong please contact us promptly to let us know.</p>

        <h3>8. Ownership and responsibility</h3>
        <p>You own a product once we have received payment in full. A product will be your responsibility from the time we deliver the product to the address you gave us.</p>

        <h3>9. Returning products or cancelling an order</h3>
        <p>9.1 Your right to cancel an order or return products will depend on what you have bought, whether there is anything wrong with it, how we are performing and when you decide to end the contract:</p>
        <ul>
            <li>(a) If what you have bought is faulty or misdescribed you may have a legal right to end the contract (or to get the product repaired or replaced or to get some or all of your money back), see clause 10;</li>
            <li>(b) If you want to end the contract because of something we have done or have told you we are going to do, see clause 9.2; and</li>
            <li>(c) If you have just changed your mind about the product, see clause 10. You may be able to get a refund if you are within the relevant period, but this may be subject to deductions.</li>
        </ul>
        <p>9.2 If you are cancelling an order for the one of the reasons set out at (a) to (d) below, the contract will end immediately and we will refund you in full for any product(s) which have not been provided and you may also be entitled to compensation. The reasons are:</p>
        <ul>
            <li>(a) We have told you about an error in the price or description of the product you have ordered and you do not wish to proceed;</li>
            <li>(b) There is a risk that supply of the product(s) may be significantly delayed because of events outside our control;</li>
            <li>(c) We have suspended supply of the product(s) for technical reasons, or notify you we are going to suspend them for technical reasons, in each case for a period of more than 1 (one) month; or</li>
            <li>(d) You have a legal right to end the contract because of something we have done wrong.</li>
        </ul>

        <h3>10. Returning products or cancelling an order which are faulty or misdescribed – your rights</h3>
        <p>10.1 We are under a legal duty to supply products that are in conformity with each contract. See the box below for a summary of your key legal rights in relation to the product(s). Nothing in these terms will affect your legal rights.</p>
        <p><strong>Summary of your key legal rights</strong></p>
        <p>This is a summary of your key legal rights. These are subject to certain exceptions. For detailed information please visit the Citizens Advice website <a href="http://www.adviceguide.org.uk" target="_blank">www.adviceguide.org.uk</a> or call 03454 04 05 06.</p>
        <p>The Consumer Rights Act 2015 says products must be as described, fit for purpose and of satisfactory quality. During the expected lifespan of your product your legal rights entitle you to the following:</p>
        <ul>
            <li>a) Up to 30 days: if your product(s) are faulty, then you can get an immediate refund.</li>
            <li>b) Up to six months: if your product(s) can’t be repaired or replaced, then you are entitled to a full refund, in most cases.</li>
            <li>c) Up to six years: if your product(s) do not last a reasonable length of time you may be entitled to some money back.</li>
        </ul>
        <p>10.2 If you wish to exercise your legal rights you must email us at <a href="mailto:info@worgangames.com">info@worgangames.com</a> for a return label in order to post the product(s) back to us. We will pay the costs of postage.</p>

        <h3>11. Returning products or cancelling an order because you have changed your mind</h3>
        <p>11.1 If you have bought a product(s) from our website, you may change your mind within 14 (fourteen) days after the day you (or someone you nominate) receives the product(s) or, if your product(s) are spilt into several deliveries over different days, 14 (fourteen) days after the day you (or someone you nominate) receives the last delivery (this is referred to as the “Cooling Off Period“), unless we have personalised the product for you in some way.</p>
        <p>11.2 If you bought the product(s) from us at a retail event, or in some other way apart from online or if the product(s) is personalised we will offer you a credit note for future purchases on the website. If you are returning the product(s) after the Cooling Off Period has expired you will have to pay the costs of return.</p>

        <h3>12. How to return the product(s) or cancel your order</h3>
        <p>12.1 To cancel an order or return a product, please let us know by doing one of the following:</p>
        <ul>
            <li>(a) Email or Contact Us Page. Email us at <a href="mailto:info@worgangames.com">info@worgangames.com</a> or visit <a href="https://www.worgangames.com/contact">www.worgangames.com/contact</a>. Please provide your name, home address, details of the order and, where available, your phone number and email address.</li>
        </ul>
        <p>12.2 If you cancel your order for any reason after your order has been dispatched to you or you have received your order, you must return the product(s) to us. Please email us at <a href="mailto:info@worgangames.com">info@worgangames.com</a>. If you are exercising your right to change your mind you must send off the product(s) within 14 days of telling us you wish to end the contract.</p>
        <p>12.3 We will pay the costs of return:</p>
        <ul>
            <li>(a) if the product(s) are faulty or misdescribed;</li>
            <li>(b) if you are cancelling or returning because we have told you of an upcoming change to the product or these terms, an error in pricing or description, a delay in delivery due to events outside our control or because you have a legal right to do so as a result of something we have done wrong; or</li>
        </ul>
        <p>12.4 We will refund you the price you paid for the product(s) including delivery costs (where applicable), by the method you used for payment. However, we may make deductions from the price, as follows:</p>
        <ul>
            <li>(a) we may reduce your refund of the price (excluding delivery costs) to reflect any reduction in the value of the product(s), if this has been caused by your handling them in a way which would not be permitted in a shop. If we refund you the price paid before we are able to inspect the product(s) and later discover you have handled them in an unacceptable way, you must pay us an appropriate amount.</li>
            <li>(b) the maximum refund for delivery costs will be the costs of delivery by the least expensive delivery method we offer. For example, if we offer delivery of a product within 1 – 3 days at one cost but you choose to have the product delivered within 24 hours at a higher cost, then we will only refund what you would have paid for the cheaper delivery option.</li>
        </ul>
        <p>12.5 We will make any refunds due to you as soon as possible. If you are exercising your right to change your mind during the Cooling Off Period, then your refund will be made within 14 days from the day on which we receive the product back from you or, if earlier, the day on which you provide us with evidence that you have sent the product back to us. In all other cases, your refund will be made within 14 days of your telling us you have changed your mind.</p>

        <h3>13. How we may terminate your order</h3>
        <p>13.1 We may terminate your order for a product at any time by writing to you if:</p>
        <ul>
            <li>(a) you do not make any payment to us when it is due and you still do not make payment within 7 (seven) days of us reminding you that payment is due;</li>
            <li>(b) you do not, within a reasonable time of us asking for it, provide us with information that is necessary for us to provide the product(s); or</li>
            <li>(c) you do not, within a reasonable time, allow us to deliver the product to you.</li>
        </ul>
        <p>13.2 If we terminate your order in the situations set out in clause 13.1 we will refund any money you have paid in advance for product(s) we have not provided but we may deduct or charge you reasonable compensation for the net costs we will incur as a result of your breaking the contract.</p>

        <h2>Terms relating to use of our website</h2>

        <p>14.12 We use reasonable endeavours to ensure that the information on the website is accurate and to correct any errors or omissions as soon as practicable after being notified of them. To the extent permitted by applicable law, we disclaim all warranties and representations (whether express or implied) as to the accuracy of any information contained on the website. We do not guarantee that the website will be fault free and do not accept liability for any errors or omissions.</p>
        <p>14.13 Due to the nature of electronic transmission of data over the internet any liability we may have for any losses or claims arising from an inability to access the website, or from any use of the website or reliance on the data transmitted using the website, is excluded to the fullest extent permissible by law. In no event shall we be liable for any indirect loss, consequential loss, loss of profit, data, revenue, business opportunity, anticipated savings, goodwill or reputation whether in contract, tort or otherwise arising out of or in connection with these terms and conditions or use of the website save where such liability cannot be excluded by law.</p>
        <p>14.14 We do not give any warranty that the website is free from viruses or anything else which may have a harmful effect on any technology.</p>
        <p>14.15 Notwithstanding anything else in these terms and conditions, we accept unlimited liability in respect of death or personal injury caused by our negligence.</p>

        <h2>Other important terms which apply to the purchase of products from us and use of our website.</h2>

        <h3>15. Privacy</h3>
        <p>15.1 Our Privacy Policy explains what personal information we collect, why we collect it, how we use it, the controls you have over your personal information and the procedures that we have in place to protect your privacy. It applies to personal information we collect when you order a product on the website. Please make sure that you read our Privacy Policy carefully.</p>

        <h3>16. Liability</h3>
        <p>16.1 If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking these terms, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time a contract was made, both we and you knew it might happen, for example, if you discussed it with us during the sales process.</p>
        <p>16.2 We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractor, for fraud or fraudulent misrepresentation, for breach of your legal rights in relation to the product(s) for defective products under the Consumer Rights Act 2015. A summary of which can be found at clause 10.1 and.</p>
        <p>16.3 We only supply the product(s) for domestic and private use. If you use the product(s) for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>

        <h3>17. Other important terms</h3>
        <p>18. We may transfer our rights and obligations under these terms to another organisation. If this happens full details of the organisation to whom we have transferred our rights and obligations will be available on the website.</p>
        <p>18.1 You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing.</p>
        <p>18.2 A contract is between you and us. No other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person in order to end a contract or make any changes to these terms.</p>
        <p>18.3 If a court finds part of these terms illegal, the rest will continue in force. Each of the clauses of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining clauses will remain in full force and effect.</p>
        <p>18.4 If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</p>
        <p>18.5 These terms and conditions shall be governed by and construed in accordance with English law.</p>
        <p>18.6 Any disputes shall be subject to the exclusive jurisdiction of your local courts this means that if you live in England you and we submit to the exclusive jurisdiction of the English courts. If you live elsewhere in the United Kingdom you and we submit to the exclusive jurisdiction of the courts in the country in which you live.</p>

        <h3>19. Online dispute resolution</h3>
        <p>If you have a complaint, please e-mail us at: <a href="mailto:info@worgangames.com">info@worgangames.com</a>.</p>
        <p>If you reside within the European Union or the European Economic Area, you may also be able to refer a dispute to the European Online Dispute Resolution (“ODR“) platform at <a href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>. The ODR platform is a web-based platform which is designed to help consumers who have bought the product(s) or services online. It provides access to independent alternative dispute resolution services which are usually free for you to use. Worgan Games has discretion as to whether it will agree to a complaint being resolved through the ODR platform.</p>

        <h3>20. Changes to these terms</h3>
        <p>20.1 We may revise these terms at any time to reflect changes in or to:</p>
        <ul>
            <li>(a) relevant laws or regulatory requirements;</li>
            <li>(b) security, technical or operational issues; or</li>
            <li>(c) our products.</li>
        </ul>
        <p>If we change terms, we will post summary details of the changes below. If we have your e-mail address, we may also decide to email you with information on those changes.</p>

        <h3>21. Games Rescue Service</h3>
        <p>All replacement parts are subject to stock availability and issued at our discretion as a goodwill gesture. We reserve the right to decline issue of a replacement or restrict quantities of replacements for any reason, including but not limited to the following:</p>
        <ul>
            <li>We are no longer manufacturing the game</li>
            <li>We believe that the missing part does not affect the core play mechanics of the game</li>
            <li>We suspect that the service is being abused i.e to expand gameplay for more players</li>
            <li>The game has been purchased via ‘A or B stock’ from an alternative seller</li>
        </ul>
        <p>Please note that the Games Rescue Service does not form part of the terms of sale or related contracts therein. No form of remuneration or compensation will be offered if we are unable to provide replacements.</p>
        
        <br />
        <p><strong>Latest changes to these terms:</strong></p>
        <p>17th June 2024.</p>
      </Container>
  );
};

export default Terms;
