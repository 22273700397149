import { Carousel } from "react-responsive-carousel";
import CardComponent from "./carouselCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // This line requires a CSS loader configured in your webpack.
import styles from "./carousel.module.scss";
import { Flex } from "@radix-ui/themes";
import useIsWide from "../../hooks/useIsWide";
import { useLocation } from "react-router-dom";
import { routesConfig } from "../../../App";

interface CarouselCardProps {
  background: string;
  comingSoon?: boolean;
  link: string;
}

interface CustomCarouselProps {
  cards: CarouselCardProps[];
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({
  cards,

}) => {
  const isWide = useIsWide();

  const location = useLocation();
  
  const filteredCards = cards.filter((card) => location.pathname !== routesConfig[card.link as keyof typeof routesConfig]?.path);

  return (
    <Carousel
      className={styles.CustomCarouselRoot}
      showThumbs={false}
      showArrows={false}
      infiniteLoop={true}
      showStatus={false}
      useKeyboardArrows={true}
      centerMode={true}
      centerSlidePercentage={isWide ? 60 : 80}
      autoPlay={true}
    >
      {filteredCards.map((card, index) => (
        <Flex px="6" my="8" justify="center" key={index}>
          <CardComponent card={card} />
        </Flex>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
