import {
  Box,
  Container,
  Text,
  Grid,
  Heading,
  Section,
  Flex,
} from "@radix-ui/themes";
import styles from "./HowToPlayJinx.module.scss";
import SlideIn from "../../../shared/components/slide-in/SlideIn";
import Setup from "/shared/images/JinxSetup.webp";
import Start from "/shared/images/JinxStart.webp";
import Count from "/shared/images/TheCount2.webp";
import Play from "/shared/images/PlayingCards.webp";
import Drawing from "/shared/images/DrawingCards.webp";
import Jinx from "/shared/images/Jinx.webp";
import Skip from "/shared/images/Skip.webp";
import Chain from "/shared/images/Chaining.webp";
import OddEven from "/shared/images/OddEven.webp";
import Power from "/shared/images/Power.webp";

const HowToPlayJinx: React.FC = () => {
  return (
    <Box className={styles.HowToPlayJinxContainer}>      
      <Container pb="9" className={styles.ContentContainer}>
        <Flex align="center" flexGrow={"1"} mt="9" direction={"column"}>
          <Heading as="h2" size={"9"} mb="4">
            HOW TO PLAY
          </Heading>
          <Text size={"6"}>
            Be the first player to have emptied both their hand and draw pile.
          </Text>
        </Flex>
        <Flex justify="center" flexGrow={"1"}>
        </Flex>
        <Section>
          <Flex justify="center" align="center">
              <img src={Setup} alt="Jinx Setup" width={"80%"} />
          </Flex>
        </Section>

        <Flex direction={{ initial: "column-reverse", md: "row" }}pt="5">
          <Flex align="center" justify="center" px="7" className="halfWidth">
            {/* <SlideIn> */}
              <img src={Start} alt="Jinx Start" width={"100%"} />
            {/* </SlideIn> */}
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Starting The Game
            </Heading>
            <Text mt="3">
              <ol type="1">
                <li>
                  Shuffle and evenly distribute all of the cards face down
                  between the players to form their draw piles.
                </li>
                <li>
                  All players must place both hands on the table.
                  3...2...1... GO!
                </li>
                <li>
                  Players pick up as many cards as they wish from their draw
                  pile and look at them.
                </li>
                <li>
                  Any player with a 1 or ODD card may start the count by
                  playing their card and saying "One". The next player then
                  plays a 2 or an EVEN card, and so on.
                </li>
              </ol>
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }}pt="5">
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              The Count
            </Heading>
            <Text mt="3">
              Jinx is played with a shared count. So every player is competing
              to be the first to play the next number in the sequence from 1-10.
            </Text>
            <Text mt="3">
              When the count reaches 10, it resets back to 1. If a Reverse card
              is played, the direction of the count is reversed - descending from
              10 to 1 and resetting to 10.
            </Text>
          </Flex>
          <Flex align="center" px="7" justify="center">
            {/* <SlideIn direction="right"> */}
              <img src={Count} alt="Jinx Cards 1-10"  width={"100%"} />
            {/* </SlideIn> */}
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }} pt="5" >
          <Flex align={"center"} justify="center" px="7" className="halfWidth">
            {/* <SlideIn> */}
              <img src={Play} alt="Cards layout" width={"80%"} />
            {/* </SlideIn> */}
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Playing Cards
            </Heading>
            <Text mt="3">THERE ARE NO TURNS!</Text>
            <Text mt="3">
              Everybody plays at the same time, playing their own cards from
              their hand into their own play pile.
            </Text>
            <Text mt="3">
              To play cards from their hand into their play pile, a player must
              be holding the next number in the count, say it aloud, and then
              immediately place it into their play pile.
            </Text>
            <Text mt="3">
              All Power cards can be used once. Power cards are played to the
              side of the play pile, in a seprate "Discard" pile.
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }} pt="5">
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Drawing Cards
            </Heading>
            <Text mt="3">
              Each player has their own draw pile. Players may draw as many
              cards as they wish from their draw pile into their hands any time.
              Once picked up, cards cannot be returned to the draw pile.
            </Text>
            <Text mt="3">
              Tip: Manage a smaller hand can help players respond faster.
            </Text>
          </Flex>
          <Flex align={"center"} px="7" justify="center">
            {/* <SlideIn direction="right"> */}
              <img src={Drawing} alt="Drawing graphic" width={"100%"} />
            {/* </SlideIn> */}
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }} pt="5">
          <Flex align={"center"} justify="center" px="7" className="halfWidth">
            {/* <SlideIn> */}
              <img src={Jinx} alt="Jinx graphic" width={"100%"} />
            {/* </SlideIn> */}
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Picking Up Cards
            </Heading>

            <Text mt="3">Illegal Moves</Text>
            <Text>
              If a player says an incorrect number, or says the correct number
              but doesn't immediately play it, they must then pick up that
              number of cards from their own play pile.
            </Text>

            <Text mt="3">Jinxes</Text>
            <Text>
              When two or more players say the same number at the same time,
              they must pick up tht number of cards from their play piles. If a
              player plays the orrect number at the same time as a player
              performing an illegal move, the do not have to pick up.
            </Text>
            <Text mt="3">
              Note: The count does not stop for mistakes. Opponents may
              capitalize on the time other players spend picking up cards.
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }} pt="5">
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Skipping
            </Heading>
            <Text mt="3">
              In the rare event that nobody can play the next number in the
              count from their hand, players may say "Skip". Once all players
              have said "Skip", the count moves on to the next number.
            </Text>
          </Flex>
          <Flex align={"center"} px="7" justify="center">
            {/* <SlideIn direction="right"> */}
              <img src={Skip} alt="Skipping example" width={"90%"} />
            {/* </SlideIn> */}
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }} pt="5">
          <Flex align={"center"} justify="center" px="7" className="halfWidth">
            {/* <SlideIn> */}
              <img src={Chain} alt="Chaining example" width={"100%"} />
            {/* </SlideIn> */}
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Chaining
            </Heading>
            <Text mt="3">
              If a player wishes to play multiple cards in a row (e.g. "3","4")
              they must place each card down infront of them before calling the
              next number. Cards MUST be played down one by one.
            </Text>
            <Text mt="3">
              Quickly saying "3, 4, 5, 6" without each number card touching the
              table before calling the next one in an illegal move, resulting in
              picking up cards to the value of the highest number called.{" "}
            </Text>
          </Flex>
        </Flex>

        <Grid pt="5" columns={{ initial: "1", md: "2" }}>
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Odd / Even
            </Heading>
            <Text mt="3">
              ODD and EVEN cards are played in the same way as the 1-10 numer cards.
            </Text>
            <Text mt="3">
              If the next number in the count is odd, you may play an ODD card instead. 
            </Text>
            <Text>
            If the next number in the count is even, you may play an EVEN card instead
            </Text>
            <Text>
            When playing an ODD or EVEN card, players say the number the ODD or EVEN card is substituting.
            </Text>
              
          </Flex>
          <Flex align={"center"} px="7" justify="center">
            {/* <SlideIn direction="right"> */}
              <img src={OddEven} alt="Odd or Even explanation" width={"100%"} />
            {/* </SlideIn> */}
          </Flex>
        </Grid>

        <Flex pt="5" direction={{ initial: "column-reverse", md: "row" }}>
          <Flex align={"center"} justify="center" px="7" className="halfWidth">
            {/* <SlideIn> */}
              <img src={Power} alt="Power cards explanation" width={"100%"} />
            {/* </SlideIn> */}
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Power Cards
            </Heading>

            <Text mt="3">
              All power cards can only be used ONCE and must be played nto a separate discard pile.
            </Text>
            <Text mt="3">
            <ul>
                <li>
                  <Heading as="h3" size={"7"}>NOPE</Heading> Play Insteadof picking up cards after a Jinx or an illegal move
                </li>
                <li>
                  <Heading as="h3" size={"7"}>BURN</Heading> Get rid of your entire play pile and move it to your discard pile
                </li>
                <li>
                  <Heading as="h3" size={"7"}>REVERSE</Heading> Call "Reverse" andreverse the direction of the count.
                </li>
              </ul>
            </Text>
          </Flex>
        </Flex>

      </Container>
    </Box>
  );
};

export default HowToPlayJinx;
