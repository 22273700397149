import { useState, useEffect } from 'react';

const useIsWide = () => {
  const [isWide, setIsWide] = useState(window.innerWidth > 1024);

  const handleResize = () => {
    setIsWide(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isWide;
};

export default useIsWide;