import { Box, Flex, Heading, Text } from "@radix-ui/themes";
import CustomCarousel from "../../shared/components/carousel/carousel";
import carouselData from "../../shared/constants/homeCarousel.json";
import ExplainerSection from "../../shared/components/explainer-section/ExplainerSection";
import noiseLogo from "../../../public/shared/images/NoiseLogo.webp";
import styles from "./Noise.module.scss";
import MarketingSection from "../../shared/components/marketing-section/MarketingSection";
import noiseMarketing from "../../shared/constants/noiseMarketingData.json";
import HowToPlayNoise from "../../Noise/components/HowToPlayNoise/HowToPlayNoise";

const Noise = () => {
  return (
    <>
      <Flex className="MainContainer" direction={"column"}>
        
        <Flex my={"2"} justify={"center"} align={"center"}  direction={{initial:"column", md:"row"}}>
          <img className={styles.noiseLogo} src={noiseLogo} />
          <Box height={{initial:"", md:"250px"}} className={styles.OrangeBackground} />
          <Flex  justify="center" py={"5"} px={{initial: "1", md: "9" }}>
            <Heading as={"h1"} size={{initial: "8", md: "9" }}>Ages 8+  <br/> 2-15 Players<br/>Belly laughs Guaranteed!</Heading>
          </Flex>
        </Flex>
        
        <MarketingSection
          data={noiseMarketing}
        />

        <ExplainerSection
          title="Guess That Noise"
          description="Choose, Imitate and Guess that Noise!"
          listItems={["2-15 Players", "Ages 8+", "Quick and easy gameplay!"]}
          image="/shared/images/NoiseProductGlow.webp"
          productName={"GUESS_THAT_NOISE"}
        />

        <HowToPlayNoise />

        <Box className="ComingSoonCarouselContainer">
          <CustomCarousel cards={carouselData} />
        </Box>
      </Flex>
    </>
  );
};

export default Noise;
