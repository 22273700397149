import { Box, Container, Flex, Heading, Spinner, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTrackEvent } from "../../shared/hooks/useTrackEvent";
import { getRequest } from "../../shared/util/apiHelpers";
import { useCartStore } from "../../state";
import styles from "./SuccessfulPayment.module.scss";
import logo from "/shared/images/logo.webp";

const SuccessfulPayment = () => {
  const [orderDetails, setOrderDetails] = useState<any>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const checkoutSessionId = params.get("checkout_session_id");

  const trackEvent = useTrackEvent();

  const emptyCart = useCartStore((state) => state.emptyCart);

  useEffect(() => {
    if (orderDetails) {
      emptyCart();
      trackEvent("purchase", "purchase");
    }
  }, [orderDetails]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getRequest(
          `/order-confirmation?session_id=${checkoutSessionId}`
        );
        setOrderDetails(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (checkoutSessionId) {
      fetchData();
    }
  }, [checkoutSessionId]);

  if (!orderDetails) {
    return (
      <>
        <div>Loading Payment</div>
      </>
    );
  }
  
  return (
    <Container>
      <Flex
        my="7"
        p="4"
        direction={"column"}
        className={"testimonial-card"}
        align="center"
      >
        <Box mb="5">
          <img src={logo} width="150px" alt="Worgan Games Logo" />
        </Box>

        <Heading as="h1" size={"7"} align="center" mb="5">
          Thank you for your order!
        </Heading>

        {orderDetails ? <></> : <Spinner />}


        <Text align="center" mb="5">
          We've sent you an email to <b>{orderDetails.customerDetails.email}</b>{" "}
          with your receipt and your order details.
        </Text>

        <Text align="center" mb="5">
          We'll send you another one when your order is on it's way to: <br />
        </Text>
        <Text mb="5" align={"center"}>
          <strong>
            {orderDetails.customerDetails.name ? (
              <>
                {" "}
                {orderDetails.customerDetails.name} <br />
              </>
            ) : (
              <></>
            )}
            {orderDetails.customerDetails.address.line1 ? (
              <>
                {" "}
                {orderDetails.customerDetails.address.line1} <br />
              </>
            ) : (
              <></>
            )}
            {orderDetails.customerDetails.address.line2 ? (
              <>
                {" "}
                {orderDetails.customerDetails.address.line2} <br />
              </>
            ) : (
              <></>
            )}
            {orderDetails.customerDetails.address.city ? (
              <>
                {" "}
                {orderDetails.customerDetails.address.city} <br />
              </>
            ) : (
              <></>
            )}
            {orderDetails.customerDetails.address.postal_code ? (
              <>
                {" "}
                {orderDetails.customerDetails.address.postal_code} <br />
              </>
            ) : (
              <></>
            )}
          </strong>
        </Text>
      
        <Text align="center" mb="5">
          If you have any issues please <Link to="/contact" className={styles.Link}>contact us</Link>.
         
        </Text>

      </Flex>
    </Container>
  );
};

export default SuccessfulPayment;
