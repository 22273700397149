import React from 'react';
import { Flex } from '@radix-ui/themes';

interface NavbarCardProps {
  imageSrc?: string;
  background?: string;
}
const NavbarCard: React.FC<NavbarCardProps> = ({ imageSrc, background }) => {
  const hasBackgroundImage = !!background;
  return (
    <Flex
      justify="center"
      align="center"
      className="EnlargeCard"
      style={{ backgroundColor: hasBackgroundImage ? 'transparent' : background || 'black' }}
    >
      {hasBackgroundImage && <img src={background} alt="Background Image" className="backgroundImage" />}
      <img src={imageSrc} alt="Card Image" width="100%" className="EnlargeCardScale" />
    </Flex>
  );
};

export default NavbarCard;