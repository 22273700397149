import React, { useState, useEffect } from "react";
import {
  Select,
  TextField,
  TextArea,
  Grid,
  Box,
  Flex,
  Text,
  Card,
  Button,
} from "@radix-ui/themes";
import * as Label from "@radix-ui/react-label";
import { useContactStore } from "../../../state";
import { contactCategories } from "../../../shared/constants/globalConstants";
import * as Yup from "yup";
import styles from "./contact-us-form.module.scss";
import RedAsterisk from "../../../shared/components/red-asterisk/RedAsterisk";
import classNames from "classnames";
import Header from "../../../shared/components/header/header";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .max(30, "Name cannot be longer than 30 characters"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required")
    .max(50, "Email cannot be longer than 50 characters"),
  orderNum: Yup.string().max(
    30,
    "Order number cannot be longer than 30 characters"
  ),
  message: Yup.string()
    .required("Message is required")
    .max(500, "Message cannot be longer than 500 characters"),
});

type Field = "name" | "email" | "orderNum" | "message";

const OptOut: React.FC = () => {
  const submitContactForm = useContactStore((state) => state.submitContactForm);
  const contacted = useContactStore((state) => state.contacted);
  const subject = useContactStore((state) => state.subject);
  const name = useContactStore((state) => state.name);
  const email = useContactStore((state) => state.email);
  const orderNum = useContactStore((state) => state.orderNum);
  const message = useContactStore((state) => state.message);
  const setSubject = useContactStore((state) => state.setSubject);
  const setName = useContactStore((state) => state.setName);
  const setEmail = useContactStore((state) => state.setEmail);
  const setOrderNum = useContactStore((state) => state.setOrderNum);
  const setMessage = useContactStore((state) => state.setMessage);

  const [errors, setErrors] = useState<Record<Field, string | undefined>>({
    name: undefined,
    email: undefined,
    orderNum: undefined,
    message: undefined,
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const validateField = async (field: Field, value: string) => {
    try {
      await validationSchema.validateAt(field, { [field]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
    } catch (err) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: (err as Yup.ValidationError).message,
      }));
    }
  };

  const handleContactSubmit = async () => {
    try {
      const formValues = { subject: "WEIRDO WANTS TO OPT OUT OF FREE GAMES", name, email, orderNum: "", message: `REASON: ${message}` };
      await validationSchema.validate(formValues, { abortEarly: false });
      await submitContactForm(formValues);
    } catch (err) {
      const validationErrors: Record<Field, string | undefined> = {
        name: undefined,
        email: undefined,
        orderNum: undefined,
        message: undefined,
      };
      (err as Yup.ValidationError).inner.forEach((error) => {
        validationErrors[error.path as Field] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    const checkFormValidity = async () => {
      try {
        await validationSchema.validateAt("name", { name });
        await validationSchema.validateAt("email", { email });
        await validationSchema.validateAt("message", { message });
        setIsSubmitDisabled(false);
      } catch {
        setIsSubmitDisabled(true);
      }
    };

    checkFormValidity();
  }, [name, email, message]);

  useEffect(() => {
    if (!subject) {
      const defaultCategory = contactCategories.find(
        (category) => category.id === "7"
      );
      setSubject(defaultCategory?.label || "");
    }
  }, [subject, setSubject]);

  return (
    <>
    
    
      <Header
      heading="Opt Out of Free Games"
      subtext="So you want to opt out of receiving free games huh..."
      blur={false}
      thin={true}
    />

    <Flex
      direction="column"
      align="center"
      justify="center"
    >

      <Card className={classNames(styles.formCard, "testimonial-card")}>
        {contacted ? (
          <Box className={styles.thankYouMessage}>
            Message received. Thanks and goodbye (forever).
          </Box>
        ) : (
          <></>
        )}
        <Flex direction="column" p="7" flexGrow="1">
          <Grid columns={{ initial: "1", md: "2" }} gap="3">
            <Flex direction="column">
              <Flex justify="between" align="center">
                <Label.Root className={styles.heading}>
                  Company Name
                  <RedAsterisk />
                </Label.Root>
                {errors.name && (
                  <Text className={styles.errorMessage}>{errors.name}</Text>
                )}
              </Flex>

              <TextField.Root
              size="3"
                name="name"
                onBlur={(e) => validateField("name", e.target.value)}
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                className={`${styles.input} ${errors.name ? styles.error : ""}`}
              />
            </Flex>

            <Flex direction="column">
              <Flex justify="between" align="center">
                <Label.Root className={styles.heading}>
                  Email
                  <RedAsterisk />
                </Label.Root>
                {errors.email && (
                  <Text className={styles.errorMessage}>{errors.email}</Text>
                )}
              </Flex>

              <TextField.Root
              size="3"
                name="email"
                onBlur={(e) => validateField("email", e.target.value)}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="text"
                className={`${styles.input} ${errors.email ? styles.error : ""}`}
              />
            </Flex>

            
            
          </Grid>

          <Flex mt="3" direction="column">
            <Flex justify="between" align="center">
              <Label.Root className={styles.heading}>
                Reason
                <RedAsterisk />
              </Label.Root>
              {errors.message && (
                <Text className={styles.errorMessage}>{errors.message}</Text>
              )}
            </Flex>
          </Flex>
          <TextArea
            name="message"
            rows={5}
            value={message}
            onBlur={(e) => validateField("message", e.target.value)}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write your message here..."
            className={`${styles.textarea} ${errors.message ? styles.error : ""}`}
          />
          <Button mt="6" size="3" className="primaryButton" onClick={handleContactSubmit}
            disabled={isSubmitDisabled}>
            SUBMIT
          </Button>
        </Flex>
      </Card>
    </Flex>
    </>
  );
};

export default OptOut;
