import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import styles from "./faq-accordian.module.scss";
import { Box, Container, Heading, Section } from "@radix-ui/themes";

interface iAccordionItem {
  heading: string;
  content: string;
}

interface iFaqAccordionProps {
  items: iAccordionItem[];
}

const FaqAccordion: React.FC<iFaqAccordionProps> = ({ items }) => {
  return (
    <>
    <Section className={styles.FaqSection} >
      <Container>
      <Accordion.Root
        className={styles.AccordionRoot}
        type="single"
        defaultValue={"item-0"}
        collapsible
      >
        {items.map((item, index) => (
          <Accordion.Item
            key={index}
            className={styles.AccordionItem}
            value={`item-${index}`}
          >
            <Accordion.Header className={styles.AccordionHeader}>
              <Accordion.Trigger className={styles.AccordionTrigger}>
                <Heading as="h3" size={"7"}>
                  {item.heading}
                </Heading>
                <Box>
                  <ChevronDownIcon
                    aria-hidden
                    className={"h-7 w-7" }
                    color="black"
                  />
                </Box>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className={styles.AccordionContent}>
              <div className={styles.AccordionContentText}>{item.content}</div>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
      </Container>
      </Section>
    </>
  );
};

export default FaqAccordion;
